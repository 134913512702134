/* You can add global styles to this file, and also import other style files */
/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";
// @import "~@ngx-share/button/themes/default/default-theme";
// 3rd party libraries
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free-webfonts/scss/fa-brands.scss";
@import "../node_modules/@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss";
@import "../node_modules/@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss";

// Theme customization
@import "theme/theme";

// if you'd like to use it without importing all of bootstrap it requires
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/ngx-toastr/toastr-bs5-alert";
@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";

// sidemenu general settings
$sidemenu-width: 208px;
$sidemenu-padding: 0px 0px;
$sidemenu-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

// Note: this should be greater than base ui popup widgets
$sidemenu-zindex: 3000 !default;

// sidemenu:header
$sidemenu-header-height: 70px;
$sidemenu-border-color: #d6d6d6;

// sidemenu:body
$sidemenu-body-min-height: 300px;

// sidemenu:footer
$sidemenu-footer-height: $sidemenu-header-height - 20px;

@mixin sidemenu-divider-line($position) {
  border-#{$position}: 1px solid #ddd;
}
body {
  margin: 0px !important;
}
.fa {
  cursor: pointer;
}

// # Firefox.
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url(https://fonts.gstatic.com/s/roboto/v15/2tsd397wLxj96qwHyNIkxHYhjbSpvc47ee6xR_80Hnw.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/s/roboto/v15/vzIUHo9z-oJ4WgkpPOtg13YhjbSpvc47ee6xR_80Hnw.woff)
      format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfZBw1xU1rKptJj_0jans920.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfbO3LdcAZYWl9Si6vvxL-qU.woff)
      format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v15/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/s/roboto/v15/CrYjSnGjrRCn0pd9VQsnFOvvDin1pK8aKteLpeZ5c0A.woff)
      format("woff");
}

// # Chrome.
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url(https://fonts.gstatic.com/s/roboto/v15/2tsd397wLxj96qwHyNIkxHYhjbSpvc47ee6xR_80Hnw.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfZBw1xU1rKptJj_0jans920.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v15/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

// # Safari.
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url(https://fonts.gstatic.com/s/roboto/v15/Jzo62I39jc0gQRrbndN6nXYhjbSpvc47ee6xR_80Hnw.ttf)
      format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfSZ2oysoEQEeKwjgmXLRnTc.ttf)
      format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v15/QHD8zigcbDB8aPfIoaupKOvvDin1pK8aKteLpeZ5c0A.ttf)
      format("truetype");
}

// #IE.
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v15/5YB-ifwqHP20Yn46l_BDhA.eot);
}

// # Final:
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url(https://fonts.gstatic.com/s/roboto/v15/2tsd397wLxj96qwHyNIkxHYhjbSpvc47ee6xR_80Hnw.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/s/roboto/v15/vzIUHo9z-oJ4WgkpPOtg13YhjbSpvc47ee6xR_80Hnw.woff)
      format("woff"),
    url(https://fonts.gstatic.com/s/roboto/v15/Jzo62I39jc0gQRrbndN6nXYhjbSpvc47ee6xR_80Hnw.ttf)
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfZBw1xU1rKptJj_0jans920.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfbO3LdcAZYWl9Si6vvxL-qU.woff)
      format("woff"),
    url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfSZ2oysoEQEeKwjgmXLRnTc.ttf)
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v15/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/s/roboto/v15/CrYjSnGjrRCn0pd9VQsnFOvvDin1pK8aKteLpeZ5c0A.woff)
      format("woff"),
    url(https://fonts.gstatic.com/s/roboto/v15/QHD8zigcbDB8aPfIoaupKOvvDin1pK8aKteLpeZ5c0A.ttf)
      format("truetype"),
    url(https://fonts.gstatic.com/s/roboto/v15/5YB-ifwqHP20Yn46l_BDhA.eot);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@media print {
  .print {
    position: absolute;
    width: 100% !important;
    page-break-after: always;
  }

  .surveyContent {
    padding: 0 !important;
    position: relative;
    border: 1px solid gray !important;
    width: 955px !important;
    left: -50% !important;
  }
  fsop-drill-down .form-inline{
    display: -webkit-inline-box;
  }

  .page-content {
    background-color: white !important;
  }

  .ui-select {
    width: 100%;
  }

  /* This is to remove the arrow of select element in IE */
  select::-ms-expand {
    display: none;
  }

  select {
    // -webkit-appearance: none;
    // appearance: none;
  }

  @-moz-document url-prefix() {
    .ui-select {
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
    }

    .ui-select select {
      width: 110%;
      background-position: right 30px center !important;
      border: none !important;
    }
  }
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

form {
  label {
    font-size: 14px;
  }
}

.logo-img {
  cursor: pointer;
  // width: 120px;
}

.logo-ctn {
  position: fixed;
  background: #2d7cc7;
  margin-left: 0px;
  width: 208px;
  top: 0;
  left: 0;
}

.bg-white {
  background-color: white;
}

.sidemenu {
  float: left;
  margin-right: -100%;
  background-color: #23374f;
  position: fixed;
  width: $sidemenu-width;
  left: 0;
  top: 60px;
  bottom: 0;
  padding-top: 0px;
  height: 100%;
  display: block;

  // hide default
  // box-shadow: $sidemenu-box-shadow;
  &.open {
    display: block;
  }

  .sidemenu-header {
    height: $sidemenu-header-height;
    padding: $sidemenu-padding;
    @include sidemenu-divider-line(bottom);
  }

  .sidemenu-body.toggle-icon {
    padding-top: 48px !important;
  }

  .sidemenu-body {
    padding: $sidemenu-padding;
    max-height: calc(100vh - 5rem);
    height: calc(100% - 70px);
    overflow-y: auto;

    .active {
      background: #122134;
    }
    a {
      display: flex;
    }
    a,
    a:hover {
      color: white;
    }

    z-index: 99999 !important;
  }

  .sidemenu-body::-webkit-scrollbar {
    width: 0 !important;
  }

  .sidemenu-body {
    overflow: -moz-scrollbars-none;
  }

  .sidemenu-body {
    -ms-overflow-style: none;
  }

  .sidemenu-footer {
    height: $sidemenu-footer-height;
    padding: $sidemenu-padding;
    @include sidemenu-divider-line(top);
  }
}

// angularjs animations
.sidemenu {
  &.open-add,
  &.open-remove {
    display: block;
  }

  &.open-add {
    animation: 0.218s sidemenu-open;
  }

  &.open-remove {
    animation: 0.218s sidemenu-close;
  }
}

// keyframes animation
@keyframes sidemenu-open {
  from {
    opacity: 0;
    transform: translate3d(-$sidemenu-width / 15, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes sidemenu-close {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-$sidemenu-width / 15, 0, 0);
  }
}

$accordion-base-color: white;

// pane:content
$accordion-pane-content-padding: 10px 0px 10px 0px;
$accordion-pane-open-content-bg-color: darken($accordion-base-color, 2%);

// pane:header
$accordion-pane-normal-header-bg-color: $accordion-base-color;
$accordion-pane-hover-header-bg-color: darken(
  $accordion-pane-normal-header-bg-color,
  7%
);
$accordion-pane-open-header-bg-color: darken(
  $accordion-pane-normal-header-bg-color,
  5%
);

$accordion-pane-logo-margin-right: 8px;
$accordion-pane-open-icon-color: #1155cc;
$accordion-pane-links-color: black;
$accordion-pane-icon-padding: 0 5px;

$accordion-pane-header-padding: 14px;
$accordion-pane-header-height: 22px;

.accordion {
  list-style: none;

  li {
    display: block;
    line-height: $accordion-pane-header-height !important;
    margin: 0px !important;
    cursor: pointer;

    > a {
      //background: $accordion-pane-normal-header-bg-color;
      display: block;
      text-decoration: none;
      //color:#6f839b;
      color: #fff;
      padding: $accordion-pane-header-padding !important;
      border-top: 1px solid #35485f;

      &:hover,
      &:active {
        color: #fff;
        background: #122134;
      }

      .logo {
        display: inline-block;
        vertical-align: middle;
        width: 19px;
        margin-right: $accordion-pane-logo-margin-right;
      }

      > .title {
        /* font-family: 'open_sansregular'; */
        font-size: 13px;
        display: inline-block;
        top: 0px;
        position: relative;
      }

      > .icon {
        float: right;
        padding: $accordion-pane-icon-padding;
      }
    }

    &.subitem {
      a {
        color: #c0cdda;
        display: block;
        font-size: 12px;
        // padding: 6px 1px 3px 35px;
        padding: 7px 1px 8px 35px !important;

        span {
          vertical-align: middle;
        }

        &:hover {
          color: #fff;
          background: #1e3046;
        }
      }
    }
  }

  .sublink:hover {
    background-color: #1e3046;
  }

  .sublink {
    cursor: pointer;
    color: #ffffffdc;
    height: 30px;
    margin: 0px;
    font-size: 12px;
    padding: 8px 20px 7px 45px;
  }

  .sublink a {
    font-size: 12px;
    text-decoration: none;
  }
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  // background-color: #23374f;
}

.fsop-container {
  overflow-x: hidden;
  position: relative;
  // background-color: $light;
  transition: all 0.4s ease;
  width: calc(100% - 208px);
  margin-left: 208px;
  &:not(.no-padd) {
    &:not(.portal-detail){
      &:not(.launch-gutter){
        padding-top: 78px !important;
        @media(min-width: 768px){
          padding: 5px 30px 50px 30px;
        }
      }
    }
  }
  &.portal-detail{
    padding-top: 30px !important;
  }
  &.no-padd {
    padding-top: 60px !important;
  }

  &:not(.no) {
    min-height: 100vh;
  }

  h1 {
    font-size: 22px;
  }
}

// css inline fixes

.carrousel-btn {
  position: absolute;
  background-color: transparent;
  width: 20px;
  height: 100px;
  cursor: pointer;
}

.dashboard-small-ctn {
  padding-left: 5px;
  padding-right: 5px;
  span {
    text-align: center !important;
  }

  border-right: 1px solid #f2f6fa;
  text-align: center !important;
  text-transform: uppercase;
  min-width: 10.5% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2{
    font-size: 34px;
    color: #3F3F3F;
    margin-bottom: 5px;
    line-height: normal;
  }
}

.dashboard-stats-ctn {
  &:not(:last-child) {
    border-right: 1px solid #f2f6fa;
  }

  min-width: 25%;
  padding-left: 36px;
  .inner {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .dashboard-progress-chart-ctn {
    width: 100%;
    height: auto;
    border-bottom: 3px solid black;
  }

  .dashboard-chart-legend {
    margin-top: 5px;
    display: block;
  }

  .hint {
    display: block !important;
    margin-top: 5px;
  }
}

.icon-actions {
  padding-left: 0px;
  margin-bottom: 0px;

  li {
    display: inline-block;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  li:not(:last-child) {
    margin-right: 10px;
  }
  &.middle {
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      a {
        vertical-align: middle;
      }
    }
  }
}

.table-app-edit {
  width: 19px;
  cursor: pointer;
  height: 20px;
  display: inline-block;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -8px -255px !important;

  &:hover {
    opacity: 1;
  }
}
.table-app-impersonate{
  width: 19px;
  cursor: pointer;
  height: 18px;
  display: inline-block;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -51.5px -510px !important;

  &:hover {
    opacity: 1;
  }
}
.table-app-attr-edit {
  width: 18px;
  cursor: pointer;
  height: 20px;
  display: inline-block;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -121px -51px !important;

  &:hover {
    opacity: 1;
  }
}

.datatable-header-cell-wrapper {
  margin-right: 4px;
}

.mt-checkbox-inline {
  padding: 2px 0;

  .mt-checkbox {
    display: inline-block;
    margin-right: 20px;
    padding-left: 25px;
    padding-top: 2px;
    position: relative;
    margin-bottom: 20px;

    input {
      position: absolute !important;
      left: 0;
      top: 0;
      margin: 0 !important;
    }
  }
}

.datatable-header-cell {
  &:not(.sortable) {
    .sort-btn {
      display: none !important;
    }
  }
}

.sort-btn {
  @media (max-width: 1366px) {
    height: 14px;
  }

  &:before {
    @media (max-width: 1366px) {
      font-size: 12px;
    }
  }

  &:not(.sort-asc) {
    &:before {
      content: "\67";
      font-size: 18px;

      @media (max-width: 1366px) {
        font-size: 12px;
      }

      font-family: "data-table" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.sort-btn.sort-desc {
  &:before {
    content: "\66" !important;
  }
}

// .sort-btn:not(.sort-desc){
//   &:before {
//     content: "\67";
//     font-family: "data-table" !important;
//     font-style: normal !important;
//     font-weight: normal !important;
//     font-variant: normal !important;
//     text-transform: none !important;
//     speak: none;
//     line-height: 1;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
// }

textarea.form-control {
  min-height: 80px;
}

.table-app-delete {
  cursor: pointer;
  width: 16px;
  height: 20px;
  display: inline-block;
  margin: 0px 5px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -9px -226px !important;

  &:hover {
    opacity: 1;
  }
}

.table-app-deactivate {
  cursor: pointer;
  width: 19px;
  height: 19px;
  display: inline-block;
  // margin: 0px 5px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -76px -240px !important;

  &:hover {
    opacity: 1;
  }
}

.table-app-reactivate {
  cursor: pointer;
  width: 19px;
  height: 20px;
  display: inline-block;
  // margin: 0px 5px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -76px -208px !important;

  &:hover {
    opacity: 1;
  }
}

.table-app-view {
  cursor: pointer;
  width: 23px;
  height: 16px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -7px -412px !important;

  &:hover {
    opacity: 1;
  }
}
.display-logic-view {
  cursor: pointer;
  width: 13px;
  height: 16px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -7px -533px !important;

  &:hover {
    opacity: 1;
  }
}

.skip-logic-view {
  cursor: pointer;
  width: 17px;
  height: 16px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -7px -552px !important;

  &:hover {
    opacity: 1;
  }
}

.branch-logic-view {
  cursor: pointer;
  width: 17px;
  height: 16px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -9px -570px !important;

  &:hover {
    opacity: 1;
  }
}

.table-app-copy {
  cursor: pointer;
  width: 17px;
  height: 20px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -9px -508px !important;

  &:hover {
    opacity: 1;
  }
}

.box {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  padding: 5px;
  position: relative;

  &:before {
    width: 14px;
    height: 14px;
    content: "";
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    border: 2px solid #fff;
  }
}

.expired {
  border: 1px solid #f7314a;
  background: #f7314a;
}

.unlaunched {
  border: 1px solid #2382c4;
  background: #2382c4;
}

.scheduled {
  border: 1px solid #740e9c;
  background-color: #740e9c;
}

.launched {
  border: 1px solid #67b930;
  background: #67b930;
}

.inactive {
  border: 1px solid #ff8d32;
  background: #ff8d32;
}

.table-app-launch {
  cursor: pointer;
  width: 22px;
  height: 19px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -5px -432px;

  &:hover {
    opacity: 1;
  }
}

.table-app-deactive {
  cursor: pointer;
  height: 18px;
  width: 22px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -6px -456px !important;

  &:hover {
    opacity: 1;
  }
}

.table-app-display {
  cursor: pointer;
  height: 18px;
  width: 22px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -6px -617px !important;

  &:hover {
    opacity: 1;
  }
}

.table-app-link {
  cursor: pointer;
  width: 19px;
  height: 21px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -8px -385px !important;

  &:hover {
    opacity: 1;
  }
}

.in-active {
  opacity: 0.5 !important;
  // pointer-events: none;
  cursor: not-allowed;

  > span {
    a {
      cursor: not-allowed !important;
    }
  }
}

.active {
  opacity: 1;
  pointer-events: all;
}

.upload-norms-options{
  cursor: pointer;
  width: 23px;
  height: 20px;
  display: inline-block;
  margin: 0px 0px 0px 1px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/norms_options.svg") no-repeat;
  vertical-align: text-top;
  &:hover {
    opacity: 1;
  }
}

.new-report{
  .upload-norms-options{
    width: 23px;
    height: 22px;
    background-position: 0px 2px;
  }
}

.upload-logo{
  cursor: pointer;
  width: 23px;
  height: 22px;
  display: inline-block;
  margin: 0px 0px 0px 1px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/upload_logo.svg") no-repeat;
  vertical-align: text-top;
  &:hover {
    opacity: 1;
  }
}

.color-palatte{
  cursor: pointer;
  width: 19px;
  height: 25px;
  display: inline-block;
  margin: 0px 4px 0px 1px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/color_wheel.svg") no-repeat;
  vertical-align: text-top;
  &:hover {
    opacity: 1;
  }
  background-position: 0px 4px;
  background-size: contain;
}

.table-app-report {
  cursor: pointer;
  width: 16px;
  height: 20px;
  display: inline-block;
  margin: 0px 0px 0px 1px;
  font-size: 0;
  opacity: 0.85;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -9px -484px !important;

  &:hover {
    opacity: 1;
  }
}

.new-report{
  .table-app-view{
    width: 23px;
    height: 22px;
    background-position: -7px -408px !important;
    margin-right: 1px;
  }
}

.table-app-delete {
  cursor: pointer;
  width: 16px;
  height: 20px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  font-size: 0;
  opacity: 0.85;
  background: url("/assets/images/actionsicons.svg") no-repeat;
  vertical-align: text-top;
  background-position: -8px -297px;

  &:hover {
    opacity: 1;
  }
}

.table-app-calendar {
  cursor: pointer;
  width: 19px;
  height: 20px;
  display: inline-block;
  margin: 0px 5px 0px 0px;
  font-size: 0;
  opacity: 0.9;
  background: url("assets/images/calender-icon.png") no-repeat;
}

.ngx-datatable {
  font-family: "Open Sans", sans-serif;
  &:not(.rater-table ){
    &:not(.assessment-table){
      &:not(.category-table){
        min-height: 200px;
      }
    }
  }

  datatable-header-cell.datatable-header-cell {
    display: flex;
  }

  datatable-header-cell.datatable-header-cell
    .datatable-header-cell-template-wrap {
    white-space: normal !important;
    word-break: inherit;
  }

  datatable-body-cell.datatable-body-cell.sort-active * {
    word-break: break-word;
    text-decoration: none;
    // padding-left: 1px;
    // padding-right: 5px;
  }

  /*.sort-btn:not([class*="datatable-icon-"]):before {
      font-family: data-table;
      content: "";
      border-width: 0 4px 4px;
      border-style: solid;
      border-color: #000 transparent;
      visibility: visible;
      right: 25px;
      top: 50%;
      position: absolute;
      opacity: 0.3;
      margin-top: 2px;
      border-bottom: none;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000;
    }

    .sort-btn:not([class*="datatable-icon-"]):after {
      font-family: data-table;
      content: "";
      border-width: 0 4px 4px;
      border-style: solid;
      border-color: #000 transparent;
      visibility: visible;
      right: 25px;
      top: 50%;
      position: absolute;
      opacity: 0.3;
      margin-top: -4px;
    }*/
}

.datatable-header {
  background: #e3eaf4;
  font-size: 14px;
}

.datatable-body-row {
  border: 0px;
  width: 1814px;
}

.datatable-body-cell {
  // text-align: center !important;
  text-transform: inherit !important;

  .box {
    margin: 0;
  }
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: red;
}

#toast-container > div {
  opacity: 1;
}

.datatable-header-cell {
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}

.datatable-body-cell {
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  font-weight: 500;
}

.datatable-body-cell {
  text-align: center;
}

.progress {
  overflow: hidden;
  height: 18px;
  margin-bottom: 18px;
  background-color: whitesmoke;
  border-radius: 0px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.btn {
  border-radius: 0px;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  font-weight: 300;
  width: 95px;
  min-height: auto !important;
  &.calendar {
    height: 100%;
  }
}

.bkg-inner {
  background: #fff;
  padding: 20px 30px;
  border-bottom: 1px solid #e7eaed;

  &:not(.no) {
    min-height: 210px;
  }
}

.edit-bkg-box {
  padding: 28px 23px 5px;
  background: #f7f7f7;
  border: 1px solid #e8e8e8;
}

.modal:not(.quick-trash) {
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
}

.ngx-datatable.bootstrap .datatable-footer {
  background: white;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
  color: black;
}

.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: #23374f;
  color: white;
}

.upload-container {
  height: 200px;
  max-width: 200px;
  border: 2px dashed gray;
  cursor: pointer;

  &:not(.no-border) {
    background: url("assets/images/upload.png") no-repeat center;
  }

  margin: auto;

  &:not(.no-border) {
    .preview {
      opacity: 0;
      display: none;
    }
  }
}

.upload-container-drag {
  height: 200px;
  max-width: 200px;
  border: 3px dashed green;
  cursor: pointer;
  background: url("assets/images/upload.png") no-repeat center;
  margin: auto;
}

.preview {
  width: 100%;
  height: 100%;
}

.flex-cnt {
  display: flex;
  align-items: center;

  &.just-end {
    justify-content: flex-end;
  }
}

.no-border {
  border: 0px;
}

.flex-end {
  justify-content: flex-end;
}

.custom-cal-box {
  margin-top: 15px;
  padding: 18px 14px;
  background: #fff;
  border: 1px solid #ddd;
  width: 100%;
  position: relative;

  .switch-box-ctn {
    position: absolute;
    top: -8px;
    right: -9px;
    text-align: right;

    .switch-btn {
      position: static;
      margin-left: auto;
      background: #fff;
      color: #da0b0b;

      &:hover {
        background: #da0b0b;
        color: #fff;
        border: 1px solid #da0b0b;
      }
    }
  }
}

ngx-doc-viewer {
  display: inline-block;
}

.input-field {
  display: block;
  width: 100%;
  padding: 5px 15px;
  font-size: 13px;
  background-color: #eef2f5;
  background-image: none;
  border: 0px;
  border-radius: 0;

  &:not(.md) {
    height: 36px;
  }
}

.modal-body {
  padding: 0px;
}

.modal-content {
  height: auto;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
}

.modal-header {
  border-bottom: 1px solid white;
  text-align: center !important;
  font-style: 120%;
}

.modal-footer {
  justify-content: center;
  background-color: white !important;

  @media (max-width: 400px) {
    flex-wrap: wrap;

    .btn {
      margin-bottom: 4px;
    }
  }
}

.modal-title {
  font-weight: bold;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

.modal-holder.confirmation-holder {
  z-index: 1200 !important;

  .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

// .modal-holder.createSurvey {}

.cropper-container {
  width: 100%;
  margin: auto;
}

.settings-modal {
  // position: fixed !important;
  width: 470px;
  padding: 0px;
  border: 0px;
  border-radius: 0px;

  &.settings {
    // width: 550px;
    width: 100%;

    .modal-body {
      max-height: 520px;
      overflow: auto;
      margin: 4rem 0;
    }
  }
}

.xlModal .modal-lg {
  width: 60% !important;
  max-width: calc(60%);

  // top: 85px;
  @media (max-width: 1600px) {
    width: 1151px !important;
    max-width: 1151px;
  }
}

.xlModal {
  z-index: 1101 !important;
}

.xxlModal .modal-lg {
  margin-top: 10% !important;
  width: 90% !important;
  max-width: calc(90%);
}

.modal.modal-holder.sessionTimeoutPopup .modal-sm {
  width: 400px !important;
}

.modal.modal-holder.sessionTimeoutPopup {
  background: rgb(216, 212, 212) !important;
}

.modal.modal-holder.resetPopup,
.modal.modal-holder.test-email-modal,
.modal.modal-holder.bulk-uploader {
  z-index: 1102 !important;

  .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.modal-lg {
  .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
}

.uploadCsvModal .modal-lg {
  width: 50% !important;
  max-width: calc(50%);
}

.settingsHeaderModal .modal-lg {
  position: relative;
  left: 208px !important;
  top: 60px;
  float: left;
  width: 50% !important;
  max-width: calc(60%);
}

.settingsModal {
  &:not(.design-setting) {
    .modal-lg {
      position: relative;
      left: 208px !important;
      top: 60px;
      float: left;
      width: 40% !important;
      max-width: calc(60%);
    }
  }
}

// .settingsModal.design-setting .modal-lg {
//   width: auto !important;
//   min-width: auto;
//   max-width: 93rem;
//   min-width: 93rem;
//   max-height : calc(100vh - 40px);
// }

.settingsModal {
  &.design-setting {
    .modal-lg {
      position: fixed;
      left: 208px !important;
      top: 0;
      max-width: calc(100% - 208px);
      margin-top: 0px;
      width: 100%;
    }
  }
}
.settings-modal-preview {
  max-width: calc(100vw - 678px);
  width: 100%;
  .modal-content {
    .modal-body {
      padding: 0px;
      max-height: calc(100vh - 43px);
      overflow: auto;
    }
  }
}

.transparent-backdrop {
  position: absolute !important;
  background: transparent;
  z-index: -99 !important;
}


body.modal-open .app-header{
  padding-right: 8px !important;
}
body.modal-open .pre-footer{
  padding-right: 28px !important;
}

.gray-backdrop {
  top: 60px;
  background-color: black;
  opacity: 0.6 !important;
}

.pointer {
  cursor: pointer;
}

p {
  line-height: normal;
}

.head-action {
  margin-bottom: 20px;
}
.quick-trash {
  z-index: 9999;
}
.quick-trash .modal-sm {
  // position: fixed;
  // overflow: auto;
  // background: #fff;
  // right: 0px !important;
  // top: 3.5rem;
  // float: right;
  width: 450px !important;
  height: calc(100vh - 84px - 1.75rem);
  background: white;
  .settings-modal{
    width: 100%;
    .modal-body{
      padding: 10px;
    }
  }
  // height: 100% !important;
  // padding-left: 7px;
  // max-width: calc(50%);
  // max-height: 1280px;

  position: absolute;
  right: 0;
  top: 3.5rem;
  animation: slidein;
  animation-duration: 250ms;
  transition-timing-function: ease-in;
  // .modal-body{
  //   overflow-y: scroll;
  // }
}

.asideModal .modal-lg {
  position: fixed !important;
  background: #fff;
  right: 0px !important;
  top: 120px;
  float: right;
  width: 405px !important;
  height: 100% !important;
  // border-left: 1px solid gray;
  max-width: calc(50%);
  max-height: 1280px;
  animation: slidein;
  animation-duration: 250ms;
  transition-timing-function: ease-in;

  .modal-content {
    border: 0 !important;
  }
}

@keyframes slidein {
  0% {
    right: -200px;
  }

  100% {
    right: 0px;
  }
}

@keyframes slideout {
  0% {
    right: 0;
  }

  100% {
    right: -200px;
  }
}

.fsop-alert-modal {
  min-height: 55px;
  text-align: center !important;
  border-radius: 0px;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  h4 {
    word-break: break-all;
  }
}

.fsop-alert-modal span {
  // font-weight: bold;
  font-size: 13px;
}

.fsop-alert-modal-body {
  position: relative;
  padding: 20px 15px;
  border-right: 1px solid #fff;
}

.form-control {
  color: #000000 !important;
}

.question-container {
  margin-top: 10px;
  padding: 10px;
  display: inline-block;
  // background-color: white;
  position: relative;
  border: 1px solid black;
  border-left: 1px solid red;
  // cursor: move;
  width: 100%;
}

.popover {
  position: absolute;
  max-width: 50% !important;
  z-index: 9999 !important;
}

.bs-popover-top > .arrow::before {
  border-top-color: #e8e7e7;
}

ngb-popover-window {
  animation-name: delayedFadeIn;
  animation-duration: 0.5s;
  &:not(.bs-popover-bottom) {
    &:not(.bs-popover-top) {
      width: 40% !important;
    }
  }
}

.fsop-radio {
  -webkit-appearance: none;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  margin: 2px 0px;
  flex-shrink: 0;
  // &:after{
  //   content: "";
  //   box-sizing: border-box;
  //   display: block;
  //   height: 100%;
  //   left: 0;
  //   position: absolute;
  //   top: 0;
  //   transition: border-color ease 280ms;
  //   width: 100%;
  //   border-width: 1px;
  //   border-style: solid;
  //   border-radius: 50%;
  //   border-color: gray;
  // }
}

.fsop-radio:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  width: 100%;
  min-width: 100%;
  height: 100%;
  border: 1px solid gray;
  min-height: 100%;
  border-radius: 50%;
  transition: transform ease 280ms, background-color ease 280ms;
  background: transparent;
}

.fsop-radio:checked {
  animation-name: radio-animation;
  animation-duration: 0.14s;
  &:before {
    border: 1px solid black;
    box-shadow: inset 0px 0px 0px 3px white;
    background: black;
  }
  &:after {
    border-color: #000;
  }
}

@keyframes radio-animation {
  0% {
    box-shadow: 0 0 0px 1px #fff;
  }
  50% {
    box-shadow: 0 0 4px 2px black;
  }
  100% {
    box-shadow: 0 0 0px 0px #fff;
  }
}

.fsop-checkbox {
  width: 10px;
  height: 10px;
  display: block;
  float: left;
  position: relative;
  cursor: pointer;
  border: unset;
  // :focus{
  //   border-color: transparent !important;
  //   box-shadow: unset !important;
  // }
}

.form-check-input:focus{
  border-color: transparent !important;

  box-shadow: unset !important;

}

.fsop-checkbox:after {
  content: "";
  vertical-align: middle;
  text-align: center;
  line-height: 13px;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  background: white;
  height: 16px;
  width: 16px;
  color: #777;
  line-height: 14px;
  font-size: 10px !important;
  border: 1px solid #777;
}

.fsop-checkbox:hover:after,
.fsop-checkbox:checked:hover:after {
  background: white;
  content: "";
  color: #777;
  font-family: "fsopIcons";
}

.fsop-checkbox:checked:after {
  background: white;
  content: "";
  color: #777;
  font-family: "fsopIcons";
}

.fsop-checkbox:disabled::after{
  cursor: not-allowed;
  opacity: 0.7;
}

.per-page select {
  min-width: 150px;
  font-size: 12px;
  height: 30px;
  line-height: 29px;
  color: #485c76;
  padding: 1px 16px 0px 7px;
  border: 1px solid #f2f6f9 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(./assets/images/select.png) no-repeat 97% 50% #eef2f5;
}

.dropdown-select {
  background-color: #eef2f5;
  border: 0px;
  border-radius: 0;
  width: 100%;
  height: 36px;
  padding: 0 0 0 5px;
}

.results-filter-stat {
  float: right;
}

.light-background {
  border-color: #a2b2c5;
  border-style: solid;
}

.dark-background {
  border-color: #61646b;
  border-style: solid;
}

.gray-background {
  border-color: #6a6b70;
  border-style: solid;
}

.border-dark-header {
  border-color: #16263d;
  border-style: solid;
}

.non-editable {
  background: whitesmoke;
  opacity: 0.3;
  pointer-events: none;
}

// raj css
.text-danger {
  // color: #a70505 !important;
  color: red !important;
}

.pre-footer {
  background-color: #122234;
  margin-top: 0px;
  color: #a4b4c4;
  padding: 7px 20px;
  clear: left;
  font-family: "open_sansregular";
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  width: 100%;
  left: 0px;

  p {
    margin: 0;
  }
}

.ds-head {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  align-items: center;
  
  h2 {
    color: #2581c4;
    font-size: 15px !important;
    text-transform: uppercase;
    margin: 0 !important;
  }

  .action {
    margin-left: auto;
    display: flex;
    align-items: center;

    .emb {
      margin-left: 10px;

      &.btn {
        white-space: nowrap;
      }
    }

    .dropdown-select,
    .input-field {
      width: 148px;
      height: 30px !important;

      &.searchin {
        width: 200px;
      }
    }
  }
}

.app-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f2f6f9;
  justify-content: space-between !important;
  flex-wrap: wrap;

  &.no {
    margin-top: 0;
  }

  h2 {
    font-size: 16px !important;
    text-transform: uppercase;
    margin: 0 !important;
  }

  .app-heading {
    margin-top: 7px;
    left: 3.5rem;
    //color: #2581c4;
    font-family: "open_sansregular";
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    color: #2581c4;
  }

  .action {
    display: flex;
    margin-left: auto;

    @media (max-width: 1300px) {
      flex-wrap: wrap;
    }

    .fm3in {
      display: flex;
    }

    .emb {
      margin-left: 10px;

      .btn {
        font-size: 12px;
        height: 30px;
        min-height: 30px;
        font-weight: 300;
      }
    }

    .input-field {
      font-size: 12px;
      height: 30px !important;
      color: #485c76;
      padding: 1px 6px;
      background-color: #eef2f5 !important;
      width: 148px;

      &.searchin {
        width: 200px;
      }
    }
  }
}

.main {
  margin-left: 0px;
  padding-top: 100px !important;
  padding-bottom: 140px;
  background-color: white;
  width: 100%;
}

.margin-bottom-10 {
  margin-bottom: 12px;
}

.datatable-header-cell-template-wrap,
.datatable-body-cell-label {
  input[type="checkbox"] {
    // width: 18px !important;
    // height: 18px !important;
    margin: 4px 0 9px !important;
  }
}

ngx-datatable
  datatable-header.datatable-header
  datatable-header-cell.datatable-header-cell
  * {
  font-weight: 500 !important;
  line-height: 20px !important;
}

ngx-datatable * {
  text-align: left !important;
}

.text-underline {
  text-decoration: underline !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
// ::ng-deep .ngx-datatable.bootstrap .datatable-body-row .datatable-body-cell{
//   padding : 5px 12px!important
// }
 .ngx-datatable.bootstrap {
  .datatable-header {
    .datatable-header-cell {
      padding: 6px 12px;
      font-size: 12px;

      @media (max-width: 1366px) {
        font-size: 11px;
      }

      border-bottom: 0 solid #d1d4d7;
      display: flex;
      align-items: center;

      &.sortable {
        padding-right: 25px;
        cursor: pointer;
        .sort-btn {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 20px;
          display: inline-block;
          z-index: 1;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .datatable-body-row {
    border: 0 !important;

    > div {
      align-items: center;
    }

    &.active {
      color: inherit !important;
      background: #dadeed !important;
    }

    .datatable-body-cell {
      padding: 9px 12px 9px 12px!important;
      line-height: normal;
      font-size: 13px;
      .datatable-body-cell-label{
        padding-bottom: 5px !important;
      }
    }
  }
  &:not(.no) {
    .datatable-body-row {
      &:not(.active){
        &.datatable-row-even {
          background: #eef3f7 !important;
          width: calc(100% - 1px) !important;
        }
        &.datatable-row-odd {
          background: #f5f8fd !important;
          width: calc(100% - 1px) !important;
        }
      }
    }
  }

  [class^="datatable-icon-"]:before,
  [class*=" datatable-icon-"]:before {
    font-size: 18px;
  }
}

// .ngx-datatable.bootstrap.contact-table .datatable-body-row {
//   &.datatable-row-even {
//     background: #f5f8fd !important;
//   }
// }

.tr-list {
  display: flex;
  align-items: center;
  flex-flow: wrap;

  li {
    line-height: 1;

    a {
      margin: 6px !important;

      &:hover {
        opacity: 1 !important;
      }

      .fa-calendar {
        color: #551a8b;
        font-size: 18px;
        padding: 4px 0 0;

        &:before {
          content: "\f073";
        }
      }
    }
  }
}

.datatable-pager {
  .pager {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;

    li {
      a {
        border-radius: 0 !important;
        height: 26px !important;
        min-width: 26px !important;
        line-height: 26px !important;
        padding: 0 !important;
        background: #eef3f6;
        font-weight: normal !important;
        display: flex !important;
        align-items: center;
        justify-content: center;

        i {
          line-height: 1 !important;
        }
      }

      &.disabled {
        background: transparent;
      }
    }
  }
}

.modal-dialog {
  .modal-content {
    box-shadow: none;
    border: 0;
    border-radius: 8px;
    .fsop-alert-modal{
      border-radius: 6px;
    }
    .fsop-alert-modal-body {
      text-align: center;

      .form-group {
        text-align: left;
      }
    }
  }
}

.modal-backdrop.fade.show {
  transition: none !important;
  opacity: 0.4;
}

.form-control {
  box-shadow: none !important;
  outline: 0 !important;
  border-radius: 0;
  height: 36px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1;
  border: 0;
  background: #eef2f5 !important;
  // background: #efefef !important;
}

.btn-red {
  color: white;
  border: 0px solid #1f73ae;
  border-bottom: 0px solid #1f73ae;
  background-color: #f6586d;
  border: 0;
  border-bottom: 1px solid #1b689f;
  min-width: 90px;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active {
  color: white;
  background-color: #d64155;
}

.ngb-dp-arrow-btn {
  min-width: 0 !important;
}

.dashboard-circle-progress {
  text-align: center;
  border-right: 1px solid #f2f6fa;
  min-width: 10% !important;

  circle-progress svg {
    overflow: inherit !important;
  }
}

.dashboard-md-ctn {
  min-width: 12%;
  border-right: 1px solid #f2f6fa;
  text-align: center;
  text-transform: uppercase;
}

.switch-ctn {
  width: 50px !important;

  .switch-btn {
    position: relative;
    top: 7px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid;
    text-align: center;
    cursor: pointer;
  }
}

.datatable-ctn {
  i {
    cursor: pointer !important;
  }
}

.datatable-legends-ctn {
  // width: 400px;
  padding-top: 14px;
  height: 27px;

  .datatable-legend {
    float: left;
    line-height: 6px;
    margin-right: 30px;
  }

  .datatable-caret {
    height: 7px;
    width: 7px;
    float: left;
    margin-right: 5px;
  }

  .unlaunched-legend {
    color: #2382c4;
  }

  .launched-legend {
    color: #67b930;
  }

  .inactive-legend {
    color: #ff8d32;
  }

  .expired-legend {
    color: #f7314a;
  }

  .unlaunched-caret {
    background-color: #2382c4;
  }

  .launched-caret {
    background-color: #67b930;
  }

  .scheduled-caret {
    background-color: #740e9c;
  }

  .inactive-caret {
    background-color: #ff8d32;
  }

  .expired-caret {
    background-color: #f7314a;
  }
}

/*
  SURVEY REPORT DASHBOARD

**/

.dashboard-ctn {
  min-height: 250px !important;
}

.collapsedata {
  cursor: pointer;
  text-decoration: none;
}

.no-reminder-data-ctn {
  text-align: center;
  margin: 20px 0 20px 0;

  p {
    font-size: 16px;
  }
}

.unanswered-question-ctn {
  text-align: center;
  line-height: 324px;
}

.chart-ctn {
  display: block;
}

.unanswered-chart-ctn {
  text-align: center;
  line-height: 324px;
}

.graph-gutter {
  .hint #emails {
    padding: 5px 0px 5px 12px;
    text-align: left;
    position: relative;
  }

  .hint #links {
    padding: 5px 5px 5px 30px;
    position: relative;
  }

  .hint #emails::before {
    content: "";
    background: #d0bd52;
    display: inline-block;
    width: 6px;
    height: 6px;
    top: 8px;
    left: 0px;
    position: absolute;
    margin-right: 6px;
  }

  .hint #links::before {
    content: "";
    background: #67b930;
    display: inline-block;
    top: 8px;
    left: 18px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .bullets {
    position: absolute;
    padding: 0;
    bottom: -8px;
    right: 0px;
    width: 100%;
    left: 0;
    margin: auto;
  }

  .bullets li {
    background: none repeat scroll 0 0 #2481c4;
    border: medium none;
    cursor: pointer;
    display: inline-block;
    float: none;
    height: 10px;
    width: 10px;
  }

  .bullets li:last-child {
    margin-right: 0;
  }

  .bullets li {
    border-radius: 1000px;
  }

  .abslt {
    color: #B1BAC3;
    font-size: 11px;
    text-transform: uppercase;
    line-height: normal;
  }

  .in-active {
    opacity: 0.3;
    cursor: default;
  }

  .active {
    opacity: 1;
    cursor: default;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 26px !important;
  }

  .carousel-control-next-icon {
    width: 25px;
    height: 82px;
    left: 14px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    z-index: 98;
    background-image: url(assets/images/right-side.png);
    background-size: auto;
  }

  .carousel-control-prev-icon {
    width: 25px;
    height: 82px;
    right: 14px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    z-index: 98;
    background-image: url(assets/images/left-side.png);
    background-size: auto;
  }

  .carousel-item {
    padding: 12px 20px;
    max-width: 100%;

    h2 {
      font-size: 35px;
      color: #23384f;
      text-align: center;
      margin: 0;
    }
  }

  .progress-bar[aria-valuemin="0"] {
    color: #999;
  }

  .progress {
    box-shadow: none;
  }

  .progress-bar span {
    padding-left: 8px;
  }

  .btn-primary.outline {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .xlg {
    .modal-dialog {
      width: 1068px;
      max-width: 1068px;
    }
  }

  .modal-dialog {
    max-width: 600px;
  }
}

.sb-default .sb-wrapper {
  border-radius: 0;
  min-width: 40px;
}

.mdl1 {
  .fsop-alert-modal-body {
    font-size: 16px;

    .flexi {
      padding-top: 20px;
    }
  }
}

.rdlbl {
  .label-control {
    font-size: 14px;
    margin: 2px 0 0 15px;
  }
}

* {
  outline: 0;
}

.editor-gutter {
  display: flex;

  .editor-contents {
    // height: 742px;
    // max-height: calc(100vh - 204px);
    // overflow-x: hidden;
    // overflow-y: scroll;
    //max-width: 450px;
    position: relative;
    width: calc(100% - 390px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > .btn {
      margin-right: 5px;
      margin-bottom: 12px;
    }
  }

  .editor-sidebar {
    z-index: 3;
    width: 390px;
    margin-left: auto;
  }

  .question-container {
    &:hover {
      border: 1px solid rgba(11, 92, 173, 0.81) !important;
    }
  }
}

.editor-content {
  .editor-ember {
    border-bottom: 1px solid #f2f6fa;
    padding: 20px 20px 10px;

    h3 {
      color: #122234;
      font-size: 13px;
      margin-bottom: 10px;
      font-weight: 300;
    }

    ul {
      padding: 0;

      li {
      }
    }

    .tox-tinymce {
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 0;
      min-height: 225px;
    }

    .tox .tox-edit-area__iframe {
      background: #ebebeb !important;
    }
    .tox {
      p {
        margin: 0 !important;
      }
    }
    .mb-3,
    .my-3,
    .form-group {
      margin-bottom: 10px !important;
    }

    .form-control {
      padding: 4px 10px !important;
      font-size: 12px;
      line-height: 1;
      border-radius: 0;
      height: 28px !important;
    }

    textarea {
      &.form-control {
        height: auto !important;
      }
    }

    .input-group {
      .input-group-prepend {
        margin: 0;

        .input-group-text {
          font-size: 12px;
          color: #ed2e2e;
          border-color: #ed2e2e;
        }
      }
    }

    .form-check {
      display: flex;
      align-items: center;
      margin: 0 0 10px 0;

      &.auto-scaling {
        margin-top: 14px;
      }

      label,
      .form-control {
        margin: 0 0 0 12px;
      }

      .fsop-checkbox {
        position: relative !important;

        &:after {
          top: -6px;
        }
      }

      &.form-check-inline {
        display: inline-flex;
        margin: 12px 20px 0 0;

        .fsop-checkbox {
          &:after {
            top: -3px;
          }
        }
      }
    }

    .groups {
      padding: 11px;
      margin-bottom: 10px;
      border: 1px solid #bababa;

      .form-inline {
        &.flexi {
          flex-wrap: inherit;
          white-space: nowrap;

          .form-control {
            min-width: 0;
            width: 100%;
            padding-left: 4px !important;
            padding-right: 4px !important;
          }
        }

        .form-control.no {
          width: 100%;
        }
      }
      &.validation-box{
        .form-inline,.form-group{
          > label{
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  .form-action {
    margin: 22px 0;
    text-align: center;

    .btn + .btn {
      margin-left: 10px;
    }
  }

  .btng {
    margin-bottom: 10px;

    .btn + .btn {
      margin-left: 10px;
    }
  }
}

.page-break {
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;
  background: #fbfbf5;
  padding: 0 4px;
  margin: -1px;
  border: 1px solid #e4d799;

  .dotted {
    height: 2px;
    width: 100%;
    background: url(assets/images/divider.png) repeat-x center left;
  }

  a {
    border: 1px solid #6b5e5e;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    margin-left: 8px;
  }

  .lh18 {
    line-height: 18px;
  }
}

.modal-content {
  .modal-head {
    height: 50px;
    text-align: center !important;
    border-radius: 0px;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: bold;
      font-size: 15px;
    }
  }
}

.viewst {
  font-size: 14px;

  .dropdown-select {
    height: 30px !important;
    display: inline-block;
    width: 50px;
  }
}

.ngx-datatable.bootstrap .datatable-footer {
  color: #333;
  margin-bottom: 20px;
}

.ngx-datatable.bootstrap .datatable-footer .page-count,
.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  padding: 0;
  margin: 0;
}

input[type="checkbox"]:checked:after {
  background: #fff !important;
  content: "" !important;
  color: black !important;
  font-size: 11px !important;
  font-family: "fsopIcons";
}

.datatable-body {
  input[type="checkbox"]:after {
    line-height: 15px !important;
    border: 1px solid #999 !important;
  }
}

// .modal.fade .modal-dialog {
//   transform: none !important;
// }

// .compose-mail {
//   font-size: 14px;
//   .form-group {
//     margin-bottom: 20px;
//     .form-group {
//       margin-bottom: 0px;
//     }
//   }
//   .tox-editor-container {
//     .mce-content-body {
//       font-size: 11px;
//     }
//     .tox-menubar,
//     .tox-toolbar {
//       background: #f0f0f0 !important;
//     }
//     .tox-editor-container {
//       background: #eef2f5 !important;
//     }
//     .tox .tox-tbtn,
//     .tox .tox-mbtn {
//       height: 30px;
//       border-radius: 0;
//       border: 1px solid transparent;
//       &:hover {
//         background: #e3e3e3;
//         border-color: #ccc;
//       }
//     }
//   }
// }

.modal {
  .col-form-label {
    color: #000;
    text-align: right;
  }
}

.col-form-label {
  position: static;
}

.toast-container.toast-top-center .ngx-toastr, .toast-container.toast-bottom-center .ngx-toastr{
  border: 1px solid rgb(240 195 109);
  background-color: rgb(249 236 190);
  font-size: 13px;
  padding: 8px 10px;
  border-radius: 0;
  color: #000;
  padding-left: 38px;
  background-size: 16px;
  background-position: 10px center;
  top: 6px !important;
  width: auto !important;
  display: inline-block;
}

.toast-top-center {
  text-align: center;

  &.toast-container .toast {
    border: 1px solid rgb(240, 195, 109);
    background-color: rgb(249, 237, 190);
    font-size: 13px;
    padding: 8px 10px;
    border-radius: 0;
    color: #000;
    padding-left: 38px;
    background-size: 16px;
    background-position: 10px center;
    top: 6px !important;
    width: auto !important;
    display: inline-block;

    &.toast-warning {
    }
  }
}

.section-content,
app-edit-response {
  ul {
    margin: 0;
    padding: 0;

    li {
      position: relative;
      list-style-type: none;
      .form-check {
        .form-check-label {
          margin-left: 3px !important;
          font-size: 14px;
        }
      }

      .fsop-checkbox {
        top: 26px;
        // left: 20px;
        z-index: 5 !important;
        position: absolute;

        &:after {
          height: 16px;
          width: 16px;
          color: #777;
          line-height: 14px;
          font-size: 10px !important;
          border: 1px solid #777;
        }
      }

      .form-check {
        &:after {
          height: 16px;
          width: 16px;
          color: #777;
          line-height: 14px;
          font-size: 10px !important;
          border: 1px solid #777;
        }

        &.no-empty {
          .fsop-checkbox {
            top: 1px;
            left: 0;
          }
        }
      }

      .question-error-container {
        display: none;
      }

      @media (max-width: 1100px) {
        .choice-container {
          overflow: auto;
          max-width: 100%;
        }
      }

      table {
        width: 100%;
      }

      .choice-structure {
        tr {
          th {
            background: #e3eaf4;
            // color: #6d7379 !important;
            padding: 0px 10px;
            text-align: left !important;
            font-weight: 400;
            // white-space: nowrap;
          }

          td {
            background: #e3eaf4;

            &:last-child {
              border-right: 0 !important;
            }

            &:first-child {
              border-right: 0 !important;
            }

            .form-check {
              padding-left: 0;
              margin-top: 0px;
            }
          }
        }

        thead {
          tr {
            th {
              text-align: center !important;
            }
          }
        }

        tbody {
          tr {
            th {
              background: #f5f8fd;
            }

            td {
              background: #f5f8fd;
              &:not(:first-child) {
                &:hover {
                  background: #d0def1 !important;

                  table td {
                    background: transparent !important;
                  }
                }
              }
            }

            &:nth-child(even) {
              th {
                background: #e3eaf4;
              }

              td {
                background: #e3eaf4;
                &:not(:first-child) {
                  &:hover {
                    background: #d0def1 !important;

                    table td {
                      background: transparent !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.dark-background {
        .choice-structure {
          tr {
            height: 40px;

            th {
              background: #28292d;
              color: #fff !important;
              padding: 0px 10px;
              text-align: left !important;
              font-weight: normal !important;
              height: 32px !important;
              // white-space: nowrap;
            }

            td {
              height: 32px !important;
              background: #e3eaf4;
              color: #fff !important;

              &:last-child {
                border-right: 0 !important;
              }

              &:first-child {
                border-right: 0 !important;
              }

              .form-check {
                padding-left: 0;
                margin-top: 0px;
              }
            }
          }

          thead {
            tr {
              th {
                text-align: center !important;
              }
            }
          }

          tbody {
            tr {
              th {
                background: #45444a !important;
              }

              td {
                background: #45444a !important;
              }

              &:nth-child(even) {
                th {
                  background: #535459 !important;
                }

                td {
                  background: #535459 !important;
                }
              }
            }
          }
        }
      }
    }

    .question-container {
      margin-top: 0;
      // z-index: 1 !important;

      // dark theme background effect -> commented the background
      // background: #fff;
      padding: 22px 20px;
      margin-bottom: 0px;
      border: 1px solid transparent !important;
      &.previewMode{
        padding: 22px 20px 38px 20px;
      }
      &.selected {
        z-index: 4 !important;
        border-color: #60ac78 !important;
        width: calc(100% + 23px);
        // border-right: 0 !important;
        border-right: 1px solid white !important;
        padding-right: 43px;
        background-color: #fff;
      }

      .form-control {
        height: auto;
      }

      select.form-control {
        height: initial;
      }

      .quest {
        &:not(.no) {
          display: flex;
          // width: 100%;
          overflow: hidden;
        }
        ul{
          margin: inherit;
          padding: inherit;
          li{
            position: inherit;
            list-style-type: disc;
            line-height: inherit;
          }
        }

        ol{
          margin: inherit;
          padding: inherit;
          li{
            position: inherit;
            list-style-type: decimal;
            line-height: inherit;
          }
        }

        .free-text {
          width: 100%;
        }

        // align-items: center;
        @media (min-width: 500px) {
          margin-left: 30px;
        }

        h3 {
          color: #bfbfbf;
          position: relative;
          font-size: 14px;
          font-weight: bold;
          min-width: 20px;
          padding-right: 25px;
          display: inline-block;
          padding-top: 2px;
          position: absolute;
          @media(max-width: 551px){
            min-width: 30px;
            padding-right: 8px;
            display: none;
          }
          &.wth-qnum {
            visibility: hidden;
            // sup {
            //   position: absolute;
            //   right: 3px;
            //   top: 4px;
            // }

            sup {
              visibility: visible;
            }
          }
        }
      }

      .list-item {
        @media (min-width: 500px) {
          margin-left: 76px;
        }
        &.forColumns {
          .list-container {
            margin-top: 8px;
            width: 100%;
            .form-check-label {
              word-break: break-word;
              border: 1px solid transparent;
            }
          }
        }
        .list-container {
          padding-top: 2px;
          padding-bottom: 2px;
          // display: inline-block;
          border: 1px solid transparent;
          &:not(:last-child){
            margin-bottom: 8px;
          }
          // background: #cacaca3d;

          // &:hover {
          //   background: #cacaca6b;
          // }

          .item {
            &.radio-item {
              margin-bottom: 0px;
            }
          }
        }

        .item {
          &:not(.form-check) {
            &:not(:last-child){
              margin-bottom: 8px;
            }
          }

          &.radio-item {
            .form-check-label {
              line-height: normal;
            }
          }

          &:not(.form-check) {
            display: flex;
            align-items: center;

            .form-control-label {
              margin: 0;
              min-width: 140px;
              display: block;
              font-size: 14px;
              margin-bottom: 0px;
            }

            .form-control {
              &.total-control {
                background: #fff !important;
                border: 1px solid #777;
              }
            }

            select {
              &.form-control {
                width: 280px;

                @media (max-width: 1100px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .invalid-pipe-text{
      .question-container{
        &.selected {
          border-color: red !important;
          border-right: 1px solid white !important;
        }
      }
      .pipetext-error{
        position: absolute;
        top: 0;
        z-index: 9;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 12px;
        background: red;
        padding: 4px;
      }
    }

    &.survey-builder {
      .question-container {
        &:not(.selected){
          &:not(:hover){
            border-bottom: 1px solid #cdd1d5 !important;
          }
        }
      }
    }
  }

  &.with-brd {
    border-left: 1px solid #cdd1d5;
    border-right: 1px solid #cdd1d5;
  }
}

.list-input-styled {
  li {
    background: #eef2f5;
    list-style: none;
    // color: #6d7379;
    padding: 7px 8px;
    min-height: 45px;
    &:not(:last-child){
      margin-bottom: 5px;
    }
    cursor: pointer !important;
  }
}

// ngb-popover-window:not(.bs-popover-left){

//   min-width: 818px;
// }
// ngb-popover-window.bs-popover-right{
//   min-width: 818px;
// }
.popover {
  border-color: #e8e7e7;
  border-radius: 0;

  &:before {
    left: -9px;
    width: 100vw;
    height: 100vh;
    top: -80px;
    background: #000;
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    border: 0;
    // content: "";
    opacity: 0.6;
    transition: 0.2s;
  }

  .popover-header {
    display: none;
  }

  .arrow {
    z-index: 9;
  }

  .popover-body {
    // padding: 18px 22px;
    background: #fff;
    position: relative;
    z-index: 9;

    .quest {
      // overflow: scroll !important;
      .quest-title {
        display: flex;

        h3 {
          color: #bfbfbf;
          position: relative;
          font-size: 14px;
          font-weight: bold;
          min-width: 44px;
          padding-right: 25px;
          display: inline-block;
        }

        h4 {
          font-size: 16px;
          font-weight: normal;
        }
      }

      .quest-body {
        padding-left: 44px;
        overflow: hidden;

        .form-control {
          height: 28px;
        }

        select.form-control {
          width: 280px;
        }

        &.side-by-side,
        &.matrix-table {
          table {
            width: 100%;
          }

          .choice-structure {
            padding: 0;

            tr {
              height: 40px;

              th {
                background: #e3eaf4;
                // color: #6d7379 !important;
                padding: 6px 10px !important;
                text-align: left !important;
                font-weight: normal !important;
              }

              td {
                padding: 6px 10px !important;
                background: #e3eaf4;

                &:last-child {
                  border-right: 0 !important;
                }

                &:first-child {
                  border-right: 0 !important;
                }

                .form-check {
                  padding-left: 0;
                  margin-top: 0px;
                }

                .fsop-checkbox {
                  top: -3px !important;
                }
              }
            }

            thead {
              tr {
                th {
                  text-align: center !important;
                }
              }
            }

            tbody {
              tr {
                th {
                  background: #f5f8fd;
                }

                td {
                  background: #f5f8fd;

                  .fsop-checkbox:after {
                    top: -5px;
                    border: 1px solid #ccc;
                  }

                  p {
                    margin: 0;
                  }

                  &:hover {
                    background: #d0def1 !important;

                    table td {
                      background: #d0def1 !important;
                    }
                  }
                }

                &:nth-child(even) {
                  th {
                    background: #e3eaf4;
                  }

                  td {
                    background: #e3eaf4;

                    &:hover {
                      background: #d0def1 !important;

                      table td {
                        background: #d0def1 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .choice-structure {
          padding: 0;

          li {
            list-style: none;

            label {
              display: flex;
              align-items: center;

              .form-check-input {
                margin: 0;
              }

              span {
                padding-left: 6px;
              }
            }
          }
        }
      }
    }
  }
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: rgba(0, 0, 0, 0.15);
}

.bs-popover-right {
  // width: min-content !important;
  width: fit-content !important;
  // max-width: calc(100vw - 300px) !important;
  max-width: 60vw !important;
}

.tox-statusbar {
  // height: auto !important;
  display: none !important;
}

// .tox-statusbar .tox-statusbar__resize-handle {
//   display: none !important;
// }

// .tox-statusbar  .tox-statusbar__text-container {
//   display: block !important;
//   text-transform: initial !important;
// }

// .tox-statusbar  .tox-statusbar__text-container .red-text {
//   color: #f32424 !important;
// }

// app-email .tox-statusbar {
//   display: none !important;
// }

#word_limit {
  margin-top: -10px;
  /* height: 25px; */
  padding: 5px;
  border: 1px solid lightgray;
  border-top: none;
}

#word_limit .red-text {
  color: #f32424 !important;
  padding-bottom: 5px;
}

.chg-pass {
  margin-top: 10px;
  display: block;
}

.remove-btn {
  display: block;
  position: relative;
  height: 30px;
  width: 200px !important;
  bottom: 0px;
  text-align: center;
  background: rgba(13, 7, 19, 0.2);
  padding: 7px 10px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin: 10px auto;
}

.permission-box {
  border: 1px solid #ddd;

  label {
    display: flex;
    margin-bottom: 0px;

    span {
      margin-left: 14px;
      line-height: normal;
    }
  }

  .permission-box-head {
    background: #f1f1f1;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .permission-box-body {
    padding: 15px;
    min-height: 157px;

    .permission-list {
      &:not(.disk) {
        list-style-type: none;
        padding: 0px;
      }

      &.disk {
        padding-left: 18px;
      }

      margin: 0;

      li:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

.remove-btn:hover {
  color: white;
  background: rgba(13, 7, 19, 0.5);
}

.bs-popover-top {
  position: absolute;
  transform: translateX(300px);
  z-index: 120 !important;
  margin-right: 2px;
  height: auto;
  width: auto !important;
  border-radius: 0 !important;
  -webkit-animation: fromRightSlideTranslate 0.1s forwards;
  -webkit-animation-timing-function: linear;
  animation: fromRightSlideTranslate 0.1s forwards;
  animation-timing-function: linear;
}

.bs-popover-left {
  position: absolute;
  transform: translateX(300px);
  z-index: 999 !important;
  margin-right: 2px;
  // margin-top: 10%;
  height: auto;
  width: 300px !important;
  border-radius: 0 !important;
  -webkit-animation: fromRightSlide 0.35s forwards;
  -webkit-animation-timing-function: linear;
  animation: fromRightSlide 0.35s forwards;
  animation-timing-function: linear;
}

.page-quick-trash-sidebar-fade {
  background: rgba(0, 0, 0, 0.23);
  content: "";
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  z-index: 10;
}

@-webkit-keyframes fromLeftSlide {
  from {
    right: 100%;
  }

  to {
    right: 380px;
  }
}

@keyframes fromLeftSlide {
  from {
    right: 100%;
  }

  to {
    right: 380px;
  }
}

@keyframes fromRightSlide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-4px);
  }
}

@-webkit-keyframes fromRightSlide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-4px);
  }
}

@keyframes fromRightSlideTranslate {
  from {
    transform: translateX(-6px);
  }

  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes fromRightSlideTranslate {
  from {
    transform: translateX(-6px);
  }

  to {
    transform: translateX(0);
  }
}

// #ngb-popover-0 > .arrow {
//   border: none !important;
//   visibility: hidden;
// }

// survey dashboard sidemnu icons
.icon-multichoice {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -385px !important;
}

.icon-textentry {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -403px !important;
}

.icon-matrixtable {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -420px !important;
}

.icon-rankorder {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -436px !important;
}

.icon-descriptive {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -454px !important;
}

.icon-dynamicsum {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -472px !important;
}

.icon-drilldown {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -488px !important;
}

.icon-sidebyside {
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  background: url("assets/images/sprites.png") no-repeat 0px 0px !important;
  background-position: 0px -506px !important;
}

.icon-spellcheck {
  background: url("assets/images/spell-check.png") no-repeat !important;
  background-size: cover !important;
}

.icon-add {
  width: 15px;
  height: 15px;
  float: right;
  margin-right: 10px;
  display: inline-block;
  background: url("./assets/images/actionsicons.svg") no-repeat;
  background-position: -68px -4px !important;
}
.icon-captcha,
.svg-captcha {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  display: inline-block;
  background: url("./assets/images/actionsicons.svg") no-repeat !important;
  background-position: -53px -493px !important;
}
.subitem {
  .icon-add {
    margin-top: 6px;
    opacity: 0.9;
  }
}

.loader {
  position: relative;
  margin: auto;
  border: 2px solid #f3f3f3;
  /* Light grey */
  border-top: 2px solid black;
  /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.small {
  text-transform: lowercase;
  font-size: 15px;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#chartjs-tooltip {
  opacity: 1;
  width: 150px;
  position: absolute !important;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 15px;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.chartjs-tooltip-key {
  display: inline-block !important;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.table-pdf {
  display: block;
  width: 100% !important;

  table,
  th,
  td {
    padding: 0px !important;
    border: 1px solid black;
    border-collapse: collapse;
  }

  th {
    text-align: left;
  }
}

.valid-email-tick {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: green;
}

.profile-image-file-input {
  opacity: 0;
  height: 100%;
  cursor: pointer;
}

sup {
  color: red;
  top: -0.4em;
}

.actions {
  text-align: center;
  padding: 20px 0;

  button {
    &:not(.no-mar) {
      margin-left: 10px;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: gray;
  margin-top: 20px;
  opacity: 0.3;
}

.login-container {
  width: 100%;
  min-height: 100vh !important;
  background: url("assets/images/bg.jpg") no-repeat center center fixed;
  background-size: cover;
  overflow: auto;
}

.app-header {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #f3f3f3;
}

.logo-fixed {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2381c4 !important;
  height: 60px;
  position: fixed;
  background: #2d7cc7;
  margin-left: 0px;
  width: 208px;
}

.no-data-found {
  text-align: center;
  color: black;
  padding: 10px 10px;
  font-size: 14px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-transparent {
  background-color: transparent;
}

.muted-text {
  font-size: 10px;
  color: #9d9d9d;
}

.edged-top-bottom {
  height: 100px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;

  p {
    font-size: 18px;
    line-height: 100px;
  }
}
.fsop-container {
  &.error-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    width: 100%;
    .error-container {
      padding: 40px 30px;
      p {
        font-size: 15px;
        color: red;
        margin-bottom: 0;
      }
    }
    &.iframe {
      margin-left: 0px;
      width: 100%;
    }
  }
}
.container-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.link {
  cursor: pointer;
  color: #3379b5 !important;
  font-size: 10px !important;
}

.guide-modal {
  padding: 13px;
  line-height: normal;
  overflow-y: scroll;
  height: 450px;
}

.disabled {
  background: #ccc;
  cursor: not-allowed !important;
}

.invalid-question {
  border: 1px solid red !important;
}

.btn-outline-primary {
  // border-color: #0073dd !important;
  border: 1px solid #0073dd !important;
  // border-radius: 20px !important;
}

.width-100 {
  width: 100%;
}

.required {
  color: red;
}

.side-bar-toggle {
  cursor: pointer;
  height: 27px;
  width: 30px;
  display: block;
  border: none;
  position: absolute;
  font-size: 25px;
  top: 11px;
  right: 8px;
  background: url("./assets/images/menu-burger.png") no-repeat 5px 8px;
  background-color: #d0e4f3 !important;
}

.accord-ques {
  p {
    margin-bottom: 0;
    line-height: normal;
    padding: 5px 0;
  }
}

.pull-left {
  float: left !important;
  margin-bottom: 40px;
  position: relative;
}

.norms {
  left: -25px;
  background-color: white;
  color: red;
}

.list-head {
  width: 100% !important;
}

.align-item-center {
  align-items: center;
}

.action-search-box {
  position: relative;

  .input-field {
    padding: 0 25px 0 13px !important;
  }

  &:not(.no) {
    input[type="text"] {
      min-width: 283px;
    }
  }

  .fa-times {
    position: absolute;
    right: 12px;
    top: 10px;
  }
}

.tab-pane {
  .nav-tabs {
    padding-top: 20px;
  }
}

.content-heading {
  background: #ffffff;
  padding: 20px 30px;

  .list-head {
    padding-bottom: 16px;
    border-bottom: 1px solid #f2f6f9;

    h3 {
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;
      color: #2581c4;
      margin-bottom: 0;
    }

    // .input-field{
    //   border: 1px solid #ccc;
    //   &:hover,&:focus{
    //     border: 1px solid #bbb;
    //   }
    // }
    .align-content-between {
      position: relative;

      .fa-times {
        position: absolute;
        right: 28px;
        top: 12px;
      }
    }
  }

  .custom-input {
    border-radius: 20px !important;
    background-color: transparent !important;
    // color: white !important;
    border: 1px solid whitesmoke !important;

    // ::-webkit-input-placeholder { /* Edge */
    //   color: white;
    // }

    // :-ms-input-placeholder { /* Internet Explorer */
    //   color: white;
    // }

    // ::placeholder {
    //   color: white;
    // }
  }
}

.width100 {
  width: 100% !important;
}

.is_required {
  color: red;
  font-size: 12px;
}

@media (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
}

.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.editor-ember #inputGroup-sizing-sm {
  border-radius: 50%;
  // padding: 0 0.5rem;
  // padding: 0.25rem 0.4rem;
}

a.sample-color {
  color: #4d9456 !important;
}

.viewer-header {
  background-color: #16263d;
  min-height: 50px !important;
  color: white;
  margin-top: 15px !important;
  //padding-top: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  h6 {
    margin-top: 10px !important;
  }

  select {
    margin-top: 10px !important;
    color: black !important;
  }
}

.dark-bg {
  background-color: #122134;

  h2 {
    color: white !important;
  }

  height: 45px !important;
}

.blue-highlight {
  text-decoration: underline !important;
  color: #0073dd !important;
  font-weight: 600 !important;
}

.cursor-ban {
  cursor: not-allowed !important;
}

.svg {
  background: url("./assets/images/actionsicons.svg") no-repeat;
  display: inline-block;

  &.svg-dashboard {
    width: 19px;
    height: 19px;
    background-position: -7px -361px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-role {
    width: 20px;
    height: 18px;
    background-position: -8px -310px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-au-management {
    width: 20px;
    height: 18px;
    background-position: -7px -68px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-su-management {
    width: 20px;
    height: 19px;
    background-position: -7px -334px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-all-survey {
    width: 20px;
    height: 19px;
    background-position: -41px -28px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-survey {
    width: 19px;
    height: 18px;
    background-position: -41px -3px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-mail {
    width: 19px;
    height: 18px;
    background-position: -75px -299px;
    margin-right: 10px;
    vertical-align: middle;
  }
  &.svg-data-requests {
    width: 19px;
    height: 20px;
    background-position: -93px -51px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.del-survey {
    width: 20px;
    height: 19px;
    background-position: -42px -53px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.mng-grp-survey {
    width: 20px;
    height: 19px;
    background-position: -7px -310px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-question {
    width: 20px;
    height: 19px;
    background-position: -42px -79px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-design {
    width: 20px;
    height: 19px;
    background-position: -43px -104px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-config {
    width: 20px;
    height: 19px;
    background-position: -43px -127px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-spell {
    width: 20px;
    height: 19px;
    background-position: -44px -150px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &.svg-multichoice {
    width: 14px;
    height: 14px;
    background-position: -52px -345px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-textentry {
    width: 14px;
    height: 14px;
    background-position: -52px -364px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-matrixtable {
    width: 14px;
    height: 14px;
    background-position: -52px -383px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-rankorder {
    width: 14px;
    height: 14px;
    background-position: -52px -401px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-descriptive {
    width: 14px;
    height: 14px;
    background-position: -52px -420px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-dynamicsum {
    width: 14px;
    height: 14px;
    background-position: -52px -437px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-drilldown {
    width: 14px;
    height: 14px;
    background-position: -52px -455px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-sidebyside {
    width: 14px;
    height: 14px;
    background-position: -52px -473px;
    margin-right: 6px;
    vertical-align: middle;
  }
  &.svg-captcha {
    width: 14px;
    height: 14px;
    background-position: -53px -493px;
    margin-right: 6px;
    vertical-align: middle;
  }

  &.svg-download {
    width: 20px;
    height: 18px;
    background-position: -68px -25px;
    vertical-align: middle;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
  &.site-embed {
    width: 40px;
    height: 40px;
    background-position: -128px -2px;
  }
  &.site-intercept {
    width: 40px;
    height: 40px;
    background-position: -170px -2px;
  }
  &.svg-upload {
    width: 20px;
    height: 18px;
    background-position: -68px -72px;
    vertical-align: middle;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  &.svg-data-icon {
    width: 16px;
    height: 20px;
    background-position: -72px -98px;
    margin-right: 6px;
    vertical-align: middle;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  &.svg-num-data-icon {
    width: 16px;
    height: 20px;
    background-position: -71px -123px;
    vertical-align: middle;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  &.svg-mail-icon {
    width: 20px;
    height: 20px;
    background-position: -73px -147px;
    margin-right: 0px;
    vertical-align: middle;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  &.svg-calendar {
    position: relative;
    top: 1px;
    width: 16px;
    height: 20px;
    background-position: -9px -94px;
    vertical-align: sub;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  &.svg-schedule {
    width: 21px;
    height: 21px;
    background-position: -74px -175px;
    vertical-align: sub;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
  &.backgroundChange {
    background-position: -72px -230px !important;
    background: url(assets/images/newactionssvg.svg) -73px -172px !important;
  }
  &.norm {
    background-position: -72px -230px !important;
    background: url(assets/images/newactionssvg.svg) -73px -196px !important;
  }
}

.new-report{
  .svg.svg-download{
    width: 20px;
    height: 22px;
    background-position: -68px -22px !important; 
    scale: 1.2;
  }
}

.ngx-datatable
  datatable-body
  datatable-selection
  datatable-scroller.datatable-scroll {
  width: 100% !important;
}

.ngx-datatable{
  datatable-selection{
    display: inherit;
    overflow: hidden;
  }
}

form.form-inline {
  .list-item {
    .item {
      flex-direction: row !important;
      align-items: inherit;

      .fsop-radio {
        min-width: 20px;
        margin-top: 2px !important;
      }

      .form-check-label {
        line-height: normal;
      }
    }
  }
}

.datatable-footer-inner {
  background: #f2f2f2;

  .page-count {
    min-width: 33%;
    line-height: normal !important;
    height: auto !important;
  }
}

.carousel-indicators {
  justify-content: flex-end !important;
}

.confirm-modal-header {
  background-color: #efefef;
  padding: 15px 25px 15px 25px;
  font-weight: bold;

  h3 {
    text-align: center;
    font-size: 15px;
    color: #201f1d;
    font-weight: bold;
    margin-bottom: 0px;
  }
}

@media (max-width: 1100px) {
  form,
  .question-container {
    overflow: auto;
  }
}

.page-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 9px 0 28px;

  li {
    display: inline-block;
    font-size: 18px;

    a {
      color: #0073dd;
    }

    i {
      padding: 0 6px;
      vertical-align: middle;
    }

    span {
      font-size: 18px;
    }
  }
}

.app-version-2.appuser-1 a:hover {
  color: #272727 !important;
}

.action-col-box {
  display: flex;
  align-items: center;
  cursor: pointer;

  .see-more-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
    margin-left: 10px;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    i {
      display: inline-block;
      width: 4px;
      height: 4px;
      position: relative;
      background: #000;
      border-radius: 100%;
      margin: 0 1px;
    }
  }
}

.tab-pane {
  > .action-buttons {
    margin-top: 15px;
  }
}

.ng-select-container {
  .ng-value {
    line-height: normal;
  }
}

@media (max-width: 1400px) {
  .col-form-label {
    font-size: 13px;
  }
}

.sharebuttons-container {
  share-button {
    .sb-twitter{
      background-color: #000;
      background-image: url('/assets/images/xTwitter.svg');
      background-position: center center;
      background-repeat:no-repeat;
      background-size: 19px 16px;
      .ng-fa-icon{
        display: none;
      }
    }
    button {
      opacity: 0.9;

      &:focus {
        outline: none;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.accord-ques {
  p {
    font-family: "open_sansregular" !important;

    span {
      font-family: "open_sansregular" !important;
      font-size: inherit !important;
      color: inherit !important;
      line-height: 22px;
    }
  }
}

.dynamic-sum-table {
  tr {
    td {
      padding-right: 5px;
    }
  }
}

.form-control-label {
  padding-right: 10px;
}

.campaign-report {
  padding: 23px 23px 0;

  .control-label {
    font-weight: 7;
    margin-bottom: 0px;

    span {
      display: block;
      margin-top: 8px;
      font-weight: 300;
    }
  }

  .progress-bar {
    span {
      color: #fff;
      padding-left: 2px;
    }
  }
}

.fsop-container {
  > ngb-tabset {
    > .nav-tabs {
      margin-top: 15px;
    }
  }
}

.row-full {
  width: 100%;
  .col-left{
    &:nth-child(even){
      padding-left: 18px!important;
    }
  }
}

.col-left {
  float: left;
}

.clearfix {
  clear: both;
}

// ngb-datepicker ngb-datepicker-month-view {
//   .ngb-dp-week * {
//     font-style: normal !important;
//     text-transform: uppercase;
//     font-size: 12px;
//   }
// }

ngb-datepicker-navigation-select>.form-select{
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #fff;
}

.ngb-dp-weekday{
  font-style: normal!important;
  text-transform: uppercase;
  font-size: 12px;
  color: #17a2b8 !important;
}
.ngb-dp-weekdays{
  border-bottom: 1px solid rgba(0,0,0,.125) !important;
  background-color: #f8f9fa!important;
}
.ngb-dp-header{
  background-color: #f8f9fa!important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption){
  display: none;
}

.dashed-seperator {
  width: calc(100% - 24px);
  padding: 10px 0;
  position: relative;

  &:after {
    content: "";
    height: 0px;
    position: absolute;
    width: 100%;
    top: 9px;
    padding-top: 4px;
    border-top: 2px dashed #131313;
  }
}

.progress {
  &.circle {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
  }
}

.progress.circle:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress.circle > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress.circle .progress-left {
  left: 0;
}

.progress.circle .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 0;
  border-style: solid;
  position: absolute;
  top: 0;
  /* border-color: #ffb43e; */
  box-shadow: inset 7px 7px 0px 7px #ffb43e;
}

.progress.circle .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress.circle .progress-right {
  right: 0;
}

.progress.circle .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress.circle .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 36px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 300;
}

.progress.circle .progress-value div {
  margin-top: 10px;
}

.progress.circle .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

/* This for loop creates the 	necessary css animation names
Due to the split circle of progress-left and progress right, we must use the animations on each side.
*/
.progress.circle[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
}

.progress.circle[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress.circle[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
}

.progress.circle[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress.circle[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-3 1.5s linear forwards;
}

.progress.circle[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress.circle[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-4 1.5s linear forwards;
  transform: rotate(40deg);
}

.progress.circle[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress.circle[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress.circle[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress.circle[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress.circle[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-1 1.5s linear forwards 1.5s;
}

.progress.circle[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress.circle[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.5s;
}

.progress.circle[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress.circle[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-3 1.5s linear forwards 1.5s;
}

.progress.circle[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress.circle[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-4 1.5s linear forwards 1.5s;
}

.progress.circle[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress.circle[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-5 1.5s linear forwards 1.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}

.progress.circle {
  margin-bottom: 1em;
}

.optional-fields {
  border: 1px solid #ccc;
  padding: 30px 30px 0px;
  border-radius: 4px;
}

.group-input {
  display: flex;
  align-items: center;

  .ng-select {
    width: 100%;
  }

  .addon {
    padding: 0 10px;

    .btn {
      &.btn-outline {
        border: 1px solid #ccc;
        border-radius: 4px;
        min-width: auto;
        padding: 8px 15px;
      }
    }
  }
}

.fileinput-new {
  .fileinput-preview {
    img {
      width: 100%;
    }
  }
}

.no-pointer-events {
  position: relative;

  h6 {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    // &:before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 99;
    //   top: 0;
    //   left: 0;
    // }
  }
}

.form-inline {
  @media (max-width: 551.98px) {
    width: 100%;
  }
  .form-group {
    align-items: flex-start;
  }
}
fsop-drill-down {
  .form-inline {
    .form-control-label {
      margin-bottom: 5px !important;
    }
  }
}
.section-content {
  ul {
    .dark-background {
      padding-bottom: 0px;
      padding-right: 2px;
      margin-bottom: 0px;

      .question-container {
        // background: #46474c;

        &:hover {
          border-color: transparent !important;
        }

        h3,
        h6,
        .form-control-label,
        .form-check-label {
          // color: #fff;
        }

        .list-input-styled li {
          background: #57585d;
          color: #ffffff;
        }

        .fsop-radio {
          &:before {
            // background: transparent;
          }

          &:checked {
            &:before {
              // background: #fff;
            }
          }
        }

        .form-control {
          background: #57585d !important;
          color: #fff !important;
        }
      }
    }

    .gray-background {
      margin-bottom: 0px;
      padding-bottom: 0px;
      padding-right: 2px;

      .question-container {
        // background: #fff;

        &:hover {
          border-color: transparent !important;
        }
      }
    }

    .light-background {
      border-width: 1px 1px 1px 2px !important;
      border-right-color: #dcdcdd !important;
      border-bottom-color: #dcdcdd !important;
      padding-bottom: 0px;
      padding-right: 2px;

      &:hover {
        border-color: #2481c4 !important;
      }

      .question-container {
        // background: #fff;

        &:hover {
          border-color: transparent !important;
        }
      }
    }
  }

  background-color: white;

  h3 {
    margin: 0;
    padding: 0px 0px;
    font-size: 22px;
  }
}

.dark-progress {
  box-shadow: inset 0 0px 2px rgba(255, 255, 255, 0.27) !important;
  background-color: #37383c !important;

  .counter {
    background: #ffffff !important;
    color: #28292d !important;
  }

  .progressbar {
    background: #a8abb2;
  }
}

.gray-progress {
  box-shadow: inset 0 0px 2px #7a7b80 !important;
  background-color: #c3c3c3 !important;

  .counter {
    background: #464b51 !important;
    color: #fff !important;
  }

  .progressbar {
    background: #7a7b80;
  }
}

.default-progress {
  box-shadow: inset 0 0px 2px #7a7b80 !important;
  background-color: #fff !important;

  .counter {
    background: #275080 !important;
    color: #fff !important;
  }

  .progressbar {
    background: #2481c4;
  }
}

.light-progress {
  box-shadow: inset 0 0px 2px #d4dbe5 !important;
  background-color: #ebeef3 !important;

  .counter {
    background: #6f7783 !important;
    color: #fff !important;
  }

  .progressbar {
    background: #a2b2c5;
  }
}

.print.survey-title {
  position: relative;
  display: none;

  h3 {
    text-align: left;
    position: absolute;
    left: 50%;
    display: inline-block;
  }
}

@media print {
  body * {
    visibility: hidden;
    // height: auto !important;
    // display: block;

    // max-height: auto !important ;
    // overflow-x: visible !important;
    // overflow-y: visible !important;
    // background-color: white;
  }

  body {
    background-color: white !important;
  }

  // body .print.survey-title{
  //   display: block !important;
  //   visibility: visible !important;
  // }
  .print.survey-title {
    display: block;

    h3 {
      text-align: left;
      position: absolute;
      left: -56%;
      top: -50px;
      display: inline-block;
    }
  }

  #root,
  #root * {
    visibility: visible;
    // color: white;
    // font-size: 5rem;
  }

  #root {
    .section-content {
      ul {
        .question-container {
          border: 0px solid #96a7b9 !important;
          border-bottom: 1px solid #96a7b9 !important;
        }
      }
    }

    .section {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }

    // .toast-top-center{
    //   display: none !important;
    //   visibility: hidden;
    //   opacity: 0;
    // }
    .survey-title {
      margin-left: 40px;
      font-size: 30px;
    }
  }

  #root {
    // position: absolute;
    // left: 50%;
    // width: 100vw;
    // top: 0;
    display: block;
    position: absolute;
    top: 50px;
    font-size: 18px;
    // min-width: 50vw;
    // max-width: 80vw;
    // width: 50vw;
    // left: 50%;
    // margin-left: 0;
    width: 700px;
    left: 50%;
    margin-left: -150px;
    text-align: center;

    .accord-ques {
      position: relative;
      top: 100px !important;
    }
  }

  .safari-print {
    width: 800px !important;
    left: 45% !important;
    margin-left: 0 !important;
  }
}

.p-footer,
.pre-footer {
  p span.pull-right a {
    margin: 0 16px;
    color: white;
  }
}

.tox.tox-silver-sink.tox-tinymce-aux .tox-notifications-container {
  display: none !important;
}

.sm {
  font-size: 12px;
}

.txt-grey {
  color: #000;
}

.section-content ul li {
  .form-check {
    // margin-top: 8px;
  }
}

app-survey-preview {
  .section-content ul li {
    .form-inline {
      .list-item {
        .item:not(.form-check) {
          padding-top: 5px !important;

          .fsop-checkbox {
            top: -2px;
            margin-top: 2px !important;

            ~ .form-check-label {
              margin-left: 0px !important;
              white-space: nowrap;
              position: relative;
              top: 4px;
            }
          }
        }

        .form-check {
          .fsop-checkbox {
            // margin: 0 !important;
          }
        }
      }
    }
  }
}

// .surveyContent {
//   .section-content h3{
//     padding: 3px 0px;
//   }
// }
app-survey-report {
  .toggle {
    p {
      margin-bottom: 0px;
    }
  }
}

.pdf-q-head {
  width: 100%;

  tbody {
    tr {
      td {
        background-color: #eef2f5;
        padding: 12px 11px 12px 12px;
        font-size: 14px !important;
        box-shadow: inset 0 0 0px 100px #eef2f5;

        p {
          margin-bottom: 0px !important;
          font-size: 14px !important;

          span {
            font-size: inherit !important;
            font-family: inherit !important;
          }
        }
      }
    }
  }
}

.quest {
  h6 * {
    overflow-wrap: anywhere;
  }
}
.d-flex {
  display: flex;
}
.quest {
  h6 {
    @media (min-width: 768px) {
      max-width: calc(100% - 53px);
      padding-left: 46px;
    }
    word-break: break-word;
    min-height: auto;
    line-height: 1.4;
    p {
      &:last-child {
        margin-bottom: 0px;
      }
      width: 100%;
      // overflow: auto;
      line-height: inherit;
      span {
        font-size: inherit;
      }
    }
  }

  &:not(.descType) h6 {
    @media(min-width: 552px){
      max-width: calc(100% - 50px) !important;
      padding-left: 44px;
    }
  }

  &:not(.descType) h6 * {
    // max-width: 100% !important;
  }
}

@media(min-width: 552px){
  .form-label-above {
    .list-container {
      border: 1px solid transparent;
      .form-check {
        padding-right: 1.25rem !important;
  
        .form-check-label {
          margin-left: 0px !important;
        }
      }
      .radio-item {
        padding-right: 1.25rem !important;
        padding-left: 1.25rem !important;
      }
    }
  
    .other-textbox {
      min-width: 150px;
      margin-right: 12px;
      .form-control{
        margin-top: 12px;
      }
    }
  }
}

.bs-popover-top {
  &:not(:custom-popover) {
    @media (min-width: 1300px) {
      height: 40px !important;
    }
  }
}

fsop-side-by-side {
  .fsop-checkbox {
    &:after {
      left: -3px;
    }
  }
}

ul.defaultCursor {
  .section-header,
  .question-container {
    cursor: default !important;
  }
}

.default-theme {
  header,
  .fsop-container {
    background-color: #fff;
  }

  header {
    border-bottom: 1px solid #3379b5;

    .name-container {
      color: #ffffff;
    }
  }

  .fsop-container {
    margin-left: 0px;
    padding-top: 26px !important;
    width: 100%;
  }

  .survey-btn-sec {
    background-color: #2581c4;
  }

  .progress {
    box-shadow: inset 0 0px 2px #7a7b80 !important;
    background-color: #fff !important;

    .counter {
      background: #275080;
      color: #fff !important;
    }

    .progressbar {
      background: #2481c4;
    }
  }

  .section-header {
    background-color: #2481c4;
  }

  .content-background {
    border-width: 1px 1px 1px 2px !important;
    border-right-color: #dcdcdd !important;
    border-bottom-color: #dcdcdd !important;
    border-top-color: transparent !important;
    padding-bottom: 0px;
    padding-right: 2px;

    border-color: #2481c4;
    border-style: solid;

    &:hover {
      border-color: #2481c4 !important;
    }

    .question-container {
      // background: #fff;

      &:hover {
        border-color: transparent !important;
      }
      .list-item .list-container {
        cursor: pointer;
        &:not(.mcqSelected) {
          &:hover {
            background: #d0def1 !important;
          }
        }
      }
    }
    fsop-rank-order {
      .list-item {
        .form-group {
          .list-input-styled {
            li {
              &:hover {
                background: #d0def1 !important;
              }
            }
          }
        }
      }
    }
  }

  .have-header-img {
    .header-img-container {
      max-height: 85px;
      padding: 5px 0px;
      @media(max-width: 500px){
        padding: 5px 16px;
      }
      text-align: center;
      display: flex;
      align-items: center;

      .header-img {
        max-height: 75px;
        @media (min-width: 768px) {
          max-width: 340px;
        }
      }
    }
  }

  .ember > p.title-heading {
    font-size: 30px;
  }

  .title-heading {
    color: #313131;
  }
}

.dark-theme {
  header {
    background-color: #2f3134;

    .name-container {
      color: #ffffff;
    }
  }

  .fsop-container {
    margin-left: 0px;
    padding-top: 26px !important;
    width: 100%;
  }

  .survey-btn-sec {
    background-color: #6a6b70;
  }

  .progress {
    box-shadow: inset 0 0px 2px rgba(255, 255, 255, 0.27) !important;
    background-color: #37383c !important;

    .counter {
      background: #fff !important;
      color: #28292d !important;
    }

    .progressbar {
      background: #a8abb2;
    }
  }

  .section-header {
    background-color: #6a6b70;
  }

  .content-background {
    border-width: 1px 1px 1px 2px !important;
    border-right-color: #dcdcdd !important;
    border-bottom-color: #dcdcdd !important;
    border-top-color: transparent !important;
    padding-bottom: 0px;
    padding-right: 2px;

    border-color: #6a6b70;
    border-style: solid;

    &:hover {
      border-color: #6a6b70 !important;
    }

    .question-container {
      // background: #fff;

      &:hover {
        border-color: transparent !important;
      }
      .list-item .list-container {
        cursor: pointer;
        &:not(.mcqSelected) {
          &:hover {
            background: #525252 !important;
          }
        }
      }
    }
    fsop-rank-order {
      .list-item {
        .form-group {
          .list-input-styled {
            li {
              &:hover {
                background: #525252 !important;
              }
            }
          }
        }
      }
    }
  }

  .have-header-img {
    .header-img-container {
      max-height: 85px;
      padding: 5px 0px;
      @media(max-width: 500px){
        padding: 5px 16px;
      }
      text-align: center;
      display: flex;
      align-items: center;

      .header-img {
        max-height: 75px;
        @media (min-width: 768px) {
          max-width: 340px;
        }
      }
    }
  }

  .ember > p.title-heading {
    font-size: 30px;
  }

  .title-heading {
    color: #ffffff;
  }
}

.grey-theme {
  header {
    background-color: #2f3134;

    .name-container {
      color: #ffffff;
    }
  }

  .fsop-container {
    margin-left: 0px;
    padding-top: 26px !important;
    width: 100%;
    background-color: #cccccc;
  }

  .survey-btn-sec {
    background-color: #6a6b70;
  }

  .progress {
    box-shadow: inset 0 0px 2px #7a7b80 !important;
    background-color: #c3c3c3 !important;

    .counter {
      background: #464b51 !important;
      color: #fff !important;
    }

    .progressbar {
      background: #7a7b80;
    }
  }

  .section-header {
    background-color: #6a6b70;
  }

  .content-background {
    border-width: 1px 1px 1px 2px !important;
    border-right-color: #dcdcdd !important;
    border-bottom-color: #dcdcdd !important;
    border-top-color: transparent !important;
    padding-bottom: 0px;
    padding-right: 2px;

    border-color: #6a6b70;
    border-style: solid;

    &:hover {
      border-color: #6a6b70 !important;
    }

    .question-container {
      // background: #fff;

      &:hover {
        border-color: transparent !important;
      }
      .list-item .list-container {
        &:not(.mcqSelected) {
          cursor: pointer;
          &:hover {
            background: #d0def1 !important;
          }
        }
      }
    }
    fsop-rank-order {
      .list-item {
        .form-group {
          .list-input-styled {
            li {
              &:hover {
                background: #d0def1 !important;
              }
            }
          }
        }
      }
    }
  }

  .have-header-img {
    .header-img-container {
      max-height: 85px;
      padding: 5px 0px;
      @media(max-width: 500px){
        padding: 5px 16px;
      }
      text-align: center;
      display: flex;
      align-items: center;

      .header-img {
        max-height: 75px;
        @media (min-width: 768px) {
          max-width: 340px;
        }
      }
    }
  }

  .ember > p.title-heading {
    font-size: 30px;
  }

  .title-heading {
    color: #313131;
  }
}

.light-theme {
  header,
  .fsop-container {
    background-color: #fff;
  }

  header {
    border-bottom: 1px solid #3379b5;
  }

  .fsop-container {
    margin-left: 0px;
    padding-top: 26px !important;
    width: 100%;
  }

  .survey-btn-sec {
    background-color: #a2b2c5;
  }

  .progress {
    box-shadow: inset 0 0px 2px #d4dbe5 !important;
    background-color: #ebeef3 !important;

    .counter {
      background: #6f7783 !important;
      color: #fff !important;
    }

    .progressbar {
      background: #a2b2c5;
    }
  }

  .section-header {
    background-color: #a2b2c5;
  }

  .content-background {
    border-width: 1px 1px 1px 2px !important;
    border-right-color: #dcdcdd !important;
    border-bottom-color: #dcdcdd !important;
    border-top-color: transparent !important;
    padding-bottom: 0px;
    padding-right: 2px;

    border-color: #a2b2c5;
    border-style: solid;

    &:hover {
      border-color: #a2b2c5 !important;
    }

    .question-container {
      // background: #fff;

      &:hover {
        border-color: transparent !important;
      }
      .list-item .list-container {
        cursor: pointer;
        &:not(.mcqSelected) {
          &:hover {
            background: #d0def1 !important;
          }
        }
      }
    }
    fsop-rank-order {
      .list-item {
        .form-group {
          .list-input-styled {
            li {
              &:hover {
                background: #d0def1 !important;
              }
            }
          }
        }
      }
    }
  }

  .have-header-img {
    .header-img-container {
      max-height: 85px;
      padding: 5px 0px;
      @media(max-width: 500px){
        padding: 5px 16px;
      }
      text-align: center;
      display: flex;
      align-items: center;

      .header-img {
        max-height: 75px;
        @media (min-width: 768px) {
          max-width: 340px;
        }
      }
    }
  }

  .ember > p.title-heading {
    font-size: 30px;
  }

  .title-heading {
    color: #313131;
  }
}

.default-theme,
.dark-theme,
.grey-theme,
.light-theme {
  .section-header {
    line-height: 2em;
  }
  .section-content {
    ul {
      .question-container {
        .quest{
          margin-left: 0px !important;
        }
      }
    }
  }
  &.slide-transition{
    &.full-width{
      .fsop-container{
        overflow: hidden !important;
      }
    }
  }
  &:not(.custom-theme) {
    footer {
      position: fixed;
      left: 0px;
      bottom: 0px;
      height: auto;
      width: 100%;
      z-index: 999;

      .post-foot {
        background-color: #16263d;
      }

      .survey-btn-sec {
        .btn-actions {
          padding: 10px;
          justify-content: center;
        }
      }
    }
    .highestScrollBar {
      padding-left: 30px;
      padding-right: 30px;
    }
    .progress {
      &:not(.default-minmalist-progress) {
        height: 14px;
      }

      overflow: visible;
    }

    .fsop-container {
      padding-bottom: 116px;
    }

    .nmbr {
      float: right;
      color: #6d7379;
      margin-bottom: 6px;
      font-size: 11px;
    }
    &.progressbar-extremelyTop {
      .question-section-head {
        @media (min-width: 1200px) {
          margin: 0 auto;
          padding-bottom: 20px;
        }
        @media (min-width: 992px) {
          margin: 0 auto;
          padding-bottom: 20px;
        }
      }
    }
    &:not(.full-width) {
      footer {
        position: fixed;
        left: 0px;
        bottom: 0px;
        height: auto;
        width: 100%;
        z-index: 999;
      }
      &.wth-head-logo {
        &:not(.no-progressbar) {
          app-question-section {
            .section {
              // padding: 0px 20px;
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 322px);
                  min-height: calc(100vh - 322px);
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 260px);
                  min-height: calc(100vh - 260px);
                }
                // padding: 0px;
                overflow: hidden auto;
              }
            }
          }
        }
        &.no-progressbar {
          &:not(.have-title-head) {
            app-question-section {
              .section {
                // padding: 0px 20px;
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 265px);
                    min-height: calc(100vh - 265px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 205px);
                    min-height: calc(100vh - 205px);
                  }
                  // padding: 0px;
                  overflow: hidden auto;
                }
              }
            }
          }
        }
        &.have-title-head {
          &.no-progressbar {
            app-question-section {
              .section {
                // padding: 0px 20px;
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 296px);
                    min-height: calc(100vh - 296px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 230px);
                    min-height: calc(100vh - 23px);
                  }
                  // padding: 0px;
                  overflow: hidden auto;
                }
              }
            }
          }
        }
      }

      &:not(.wth-head-logo) {
        app-question-section {
          .section {
            // padding: 0px 20px;
            .section-content {
              @media (min-width: 768px) {
                max-height: calc(100vh - 247px);
                min-height: calc(100vh - 247px);
              }

              @media (max-width: 767.98px) {
                max-height: calc(100vh - 180px);
                min-height: calc(100vh - 180px);
              }

              // padding: 0px;
              overflow: hidden auto;
            }
          }
        }
        &.no-progressbar {
          &.have-title-head {
            app-question-section {
              .section {
                // padding: 0px 20px;
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 207px);
                    min-height: calc(100vh - 207px);
                  }

                  @media (max-width: 767.98px) {
                    max-height: calc(100vh - 150px);
                    min-height: calc(100vh - 150px);
                  }

                  // padding: 0px;
                  overflow: hidden auto;
                }
              }
            }
          }
        }
      }
      &.wthout-header-img-container {
        &.no-progressbar {
          &.wth-progressbar-txt {
            &.progressbar-bottom {
              &.have-title-head {
                app-question-section {
                  .section {
                    // padding: 0px 20px;
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 215px);
                        min-height: calc(100vh - 215px);
                      }

                      @media (max-width: 767.98px) {
                        max-height: calc(100vh - 160px);
                        min-height: calc(100vh - 160px);
                      }

                      // padding: 0px;
                      overflow: hidden auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.wthout-header-img-container {
        &.no-progressbar {
          &.wth-progressbar-txt {
            &.progressbar-bottom,
            &.progressbar-top {
              app-question-section {
                .section {
                  // padding: 0px 20px;
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 174px);
                      min-height: calc(100vh - 174px);
                    }

                    @media (max-width: 767.98px) {
                      max-height: calc(100vh - 60px);
                      min-height: calc(100vh - 60px);
                    }

                    // padding: 0px;
                    overflow: hidden auto;
                  }
                }
              }
            }
          }
        }
      }
      app-question-section {
        &.scroll-auto-hide {
          .section-content {
            ul {
              &.defaultCursor {
                padding-right: 7px;
                border-right: 1px solid #dcdcdd;
                border-bottom: 1px solid #dcdcdd;
                box-shadow: inset 1px 0px 0px 1px #dcdcdd;
              }
            }
            .section-header {
              // width: calc(100% + 8px) !important;
            }
            .content-background {
              &:not(:hover) {
                border-right-color: transparent !important;
              }
            }
            /* width */
            &::-webkit-scrollbar {
              width: 0px !important;
            }
          }
        }
      }
    }

    &.full-width {
      overflow: auto;
      .fsop-container {
        overflow: initial;
      }
      .section {
        margin-bottom: 0px !important;
      }
      footer {
        position: static;
      }
      &.default-theme,
      &.light-theme,
      &.dark-theme,
      &.grey-theme {
        .section-header {
          background-color: transparent  !important;
          // color: #3f3f3f !important;
          padding-left: 0 !important;
        }
        .content-background {
          border-color: transparent !important;
          border-width: 0px !important;
        }
        .question-container {
          .quest {
            margin-left: 0px !important;
          }
          .list-item {
            margin-left: 45px;
            @media(max-width: 500px){
              margin-left: 0px;
            }
          }
        }
      }
      // &.dark-theme {
      //   .section-header {
      //     background-color: #28292d !important;
      //     color: #ffffff !important;
      //   }
      // }
      // &.grey-theme {
      //   .section-header {
      //     background-color: #cccccc !important;
      //   }
      // }
      @media (min-width: 1200px) {
        .container {
          // max-width: 1100px;
          max-width: calc(100vw);
        }
      }
      &.scroll-hide {
        /* width */
        &::-webkit-scrollbar {
          width: 0px !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
          border-radius: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #c1c1c1;
          border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      &.scroll-visible {
        /* width */
        &::-webkit-scrollbar {
          width: 8px !important;
          height: 8px !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
          border-radius: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #c1c1c1;
          border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #e9e9e9;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      &.scroll-auto-hide {
        padding-right: 8px;
        // border-right: 1px solid #dcdcdd;
        @media (min-width: 768px) and (max-width: 1299.98px) {
          padding-right: 48px;
        }
        .content-background {
          &:not(:hover) {
            border-right-color: transparent !important;
          }
        }
        /* width */
        &::-webkit-scrollbar {
          width: 0px !important;
        }
      }
    }

    app-design-settings-modal {
      .settings-modal-preview {
        .modal-body {
          overflow: initial !important;
          .full-width {
            padding-left: 8px;
            padding-right: 0px;
            &.scroll-auto-hide {
              padding-right: 8px;
            }
          }
        }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-theme {
  &:not(.smooth-transition) {
    &:not(.full-width){
      .question-section-container {
        height: auto !important;
      }
    }
  }
  &:not(.full-width){
    overflow: hidden;
  }
  &.slide-transition{
    &.full-width{
      .fsop-container{
        overflow: hidden;
      }
    }
  }
  background-size: 100%;
  @media (max-width: 767.98px) {
    height: 100vh;
    overflow: auto;
  }
  &:not(.progressbar-extremelyTop) {
    @media (min-width: 1300px) {
      padding-top: 25px;
    }
  }
  &.progressbar-extremelyTop {
    padding-top: 25px;
    .fsop-container {
      margin-top: 20px;
    }
  }
  background-size: 100%;
  height: 100vh;
  @media (min-width: 768px) and (max-width: 1299.98px) {
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 768.98px) {
    padding: 10px;
  }

  .main {
    background-color: transparent;
  }

  .title-heading {
    color: #ffffff;
  }

  .section-header {
    &.no-title {
      display: none;
    }
  }

  .progress {
    height: 12px;
    box-shadow: none !important;
    margin: auto;
    &:not(.rectangular) {
      border-radius: 6px;
    }
    &.round {
      border-radius: 6px;
    }
    border-radius: 6px;
    border: 1px solid #ebebeb;

    .progressbar {
      border-radius: 0px 0px 0px 0px;
    }

    .counter {
      display: none;
    }
  }

  ul {
    .content-background:not(:last-child) {
      .question-container {
        margin-bottom: 20px !important;
      }
    }
  }

  .question-container {
    background: transparent !important;
    // padding: 0px !important;

    .quest {
      margin-left: 0 !important;
    }
    .list-item .list-container {
      cursor: pointer;
      &:not(.mcqSelected) {
        &:hover {
          background: #d0def1 !important;
        }
      }
    }
    @media (max-width: 767px) {
      &:not(.wth-out-qnum) {
        .list-item {
          margin-left: 0px !important;
        }
      }
    }
    .list-item {
      @media (min-width: 768px) {
        .list-item {
          margin-left: 44px !important;
        }
      }
      margin-right: 0;
      @media (max-width: 768px){
        margin-right: 0px;
      }
      .form-control {
        max-width: 700px;
        background: #fff !important;
        border: 1px solid #e4e4e4 !important;
        &:hover,
        &:focus {
          border: 1px solid #c5c5c5 !important;
        }
      }
      .form-group {
        max-width: 700px;
        margin-bottom: 0px;
      }
      .list-container {
        background: transparent;
        border: 1px solid transparent;
        .item {
          max-width: 700px;
        }
      }
    }
  }
  fsop-rank-order {
    .list-item {
      .form-group {
        .list-input-styled {
          li {
            &:hover {
              background: #d0def1 !important;
            }
          }
        }
      }
    }
  }

  header {
    // display: none !important;
  }

  .container {
    max-width: 100% !important;
    padding: 0px;
  }
  .highestScrollBar {
    padding: 0px 0 12px;
    @media (min-width: 1300px) {
      max-width: 1280px;
      margin: auto;
    }
  }
  .fsop-container {
    width: 100%;
    margin-left: 0px;
    padding: 0 !important;
    min-height: auto !important;

    @media (min-width: 1300px) {
      max-width: 1280px;
      margin: auto;
    }
  }

  .header-img-container {
    padding: 8px 12px;
    max-height: auto !important;
    img {
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }

  .title-heading {
    padding: 16px 20px;
    text-align: center;
    margin-bottom: 10px;
  }

  .question-section-head {
    padding: 2px 20px 8px;

    .nmbr {
      text-align: center;
      margin-top: 4px;
      margin-bottom: 9px;
      font-size: 14px;
      color: #717171;
    }
  }
  &:not(.progressbar-extremelyTop) {
    &.have-title-head,
    &.wth-head-logo {
      &:not(.no-progressbar) {
        &:not(.smooth-transition) {
          &:not(.slide-transition) {
            .question-section-head {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
    &.wth-head-logo {
      &:not(.no-progressbar) {
        &:not(.progressbar-bottom) {
          &.smooth-transition,
          &.slide-transition {
            .question-section-head {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }

  .p-footer {
    p {
      text-align: center;
    }
  }

  .survey-btn-sec {
    max-width: 1280px;
    margin: auto;

    .btn-actions {
      padding: 20px;
      // background-color: rgba(255, 255, 255, 0.8);
      border-top: 1px dashed #d5d5d5;
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

      &.btn-placement-left {
        justify-content: flex-start;
      }

      &.btn-placement-center {
        justify-content: center;
      }

      &.btn-placement-right {
        justify-content: flex-end;
      }
    }
  }
  &:not(.full-width) {
    .survey-msg-sec {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      text-align: center;
    }

    app-question-section {
      &.scroll-hide {
        .section-content {
          /* width */
          &::-webkit-scrollbar {
            width: 0px !important;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #e9e9e9;
            border-radius: 5px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 5px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }

      &.scroll-visible {
        .section-content {
          /* width */
          &::-webkit-scrollbar {
            width: 8px !important;
            height: 8px !important;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #e9e9e9;
            border-radius: 5px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 5px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }

      .section-content {
        /* width */
        &::-webkit-scrollbar {
          width: 8px !important;
          height: 8px !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
          border-radius: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #c1c1c1;
          border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
      &.scroll-auto-hide {
        .section-content {
          padding-right: 7px;
          // border-right: 1px solid #dcdcdd;
          .section-header {
            // width: calc(100% + 8px) !important;
          }
          .question-container {
            padding-right: 26px !important;
          }
          .content-background {
            &:not(:hover) {
              border-right-color: transparent !important;
            }
          }
          /* width */
          &::-webkit-scrollbar {
            width: 0px !important;
          }
        }
      }
      .section {
        // padding: 0px 20px;
        @media (max-width: 767px){
          padding: 0px;
        }
        .section-content {
          @media (min-width: 768px) {
            max-height: calc(100vh - 471px);
            min-height: calc(100vh - 471px);
          }
          @media (max-width: 767px) {
            max-height: calc(100vh - 411px);
            min-height: calc(100vh - 411px);
          }
          overflow: hidden auto;
          padding: 0px;
        }
      }
    }
    &.progressbar-extremelyTop {
      &.wthout-header-img-container {
        &.have-title-head {
          .section {
            .section-content {
              @media (min-width: 768px) {
                max-height: calc(100vh - 340px);
                min-height: calc(100vh - 340px);
              }
              @media (max-width: 767px) {
                max-height: calc(100vh - 280px);
                min-height: calc(100vh - 280px);
              }
            }
          }
        }
      }
    }
    &.progressbar-extremelyTop {
      &.wthout-header-img-container {
        &:not(.have-title-head) {
          &:not(.no-progressbar){
            .section {
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 232px);
                  min-height: calc(100vh - 232px);
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 190px);
                  min-height: calc(100vh - 190px);
                }
              }
            }
            &.wthout-foot{
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 264px);
                    min-height: calc(100vh - 264px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 150px);
                    min-height: calc(100vh - 150px);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.progressbar-extremelyTop {
      &.wth-head-logo {
        &.have-title-head {
          .section {
            .section-content {
              @media (min-width: 768px) {
                max-height: calc(100vh - 420px);
                min-height: calc(100vh - 420px);
              }
              @media (max-width: 767px) {
                max-height: calc(100vh - 330px);
                min-height: calc(100vh - 330px);
              }
            }
          }
        }
      }
    }
    &.progressbar-extremelyTop {
      &.wth-head-logo {
        &:not(.have-title-head) {
          .section {
            .section-content {
              @media (min-width: 768px) {
                max-height: calc(100vh - 350px);
                min-height: calc(100vh - 350px);
              }
              @media (max-width: 767px) {
                max-height: calc(100vh - 290px);
                min-height: calc(100vh - 290px);
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.have-title-head {
          &:not(.smooth-transition) {
            &:not(.slide-transition) {
              &:not(.no-progressbar) {
                &:not(.progressbar-extremelyTop) {
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 369px);
                        min-height: calc(100vh - 369px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 302px);
                        min-height: calc(100vh - 302px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.progressbar-bottom {
          &.have-title-head {
            &.slide-transition {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 330px);
                    min-height: calc(100vh - 330px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 330px);
                    min-height: calc(100vh - 330px);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &:not(.progressbar-bottom) {
          &.have-title-head {
            &.slide-transition {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 430px);
                    min-height: calc(100vh - 430px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 370px);
                    min-height: calc(100vh - 370px);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.progressbar-bottom {
          &:not(.have-title-head) {
            &.smooth-transition,
            &.slide-transition {
              &.no-progressbar {
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 265px);
                      min-height: calc(100vh - 265px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 205px);
                      min-height: calc(100vh - 205px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.progressbar-bottom {
          &.have-title-head {
            &.smooth-transition,
            &.slide-transition {
              &.no-progressbar {
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 345px);
                      min-height: calc(100vh - 345px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 285px);
                      min-height: calc(100vh - 285px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.progressbar-bottom {
          &:not(.have-title-head) {
            &:not(.no-progressbar) {
              &.smooth-transition {
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 315px);
                      min-height: calc(100vh - 315px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 255px);
                      min-height: calc(100vh - 255px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.progressbar-bottom {
          &.have-title-head {
            &.smooth-transition {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 330px);
                    min-height: calc(100vh - 330px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 330px);
                    min-height: calc(100vh - 330px);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &:not(.have-title-head) {
          &.slide-transition {
            .section {
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 310px);
                  min-height: calc(100vh - 310px);
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 250px);
                  min-height: calc(100vh - 250px);
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.no-progressbar-txt {
        &.have-title-head {
          &:not(.smooth-transition) {
            &:not(.slide-transition) {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 289px);
                    min-height: calc(100vh - 289px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 290px);
                    min-height: calc(100vh - 290px);
                  }
                }
              }
              &.wthout-foot{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 244px);
                      min-height: calc(100vh - 244px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 290px);
                      min-height: calc(100vh - 290px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.no-progressbar {
        &.have-title-head {
          .section {
            .section-content {
              @media (min-width: 768px) {
                max-height: calc(100vh - 294px);
                min-height: calc(100vh - 294px);
              }
              @media (max-width: 767px) {
                max-height: calc(100vh - 202px);
                min-height: calc(100vh - 202px);
              }
            }
          }
        }
      }
    }

    &.wthout-header-img-container {
      &:not(.no-progressbar) {
        &:not(.no-progressbar-txt) {
          &:not(.smooth-transition) {
            &:not(.slide-transition) {
              &.progressbar-bottom {
                &.have-title-head {
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 293px);
                        min-height: calc(100vh - 293px);
                      }
                      @media (max-width: 768px) {
                        max-height: calc(100vh - 380px);
                        min-height: calc(100vh - 380px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.no-progressbar {
      &:not(.wth-head-logo) {
        .section {
          .section-content {
            @media (min-width: 768px) {
              max-height: calc(100vh - 194px);
              min-height: calc(100vh - 194px);
            }
            @media (max-width: 767px) {
              max-height: calc(100vh - 84px);
              min-height: calc(100vh - 84px);
            }
          }
        }
        &.wthout-foot{
          .section {
          .section-content {
            @media (min-width: 768px) {
              max-height: calc(100vh - 142px);
              min-height: calc(100vh - 142px);
            }
            @media (max-width: 767px) {
              max-height: calc(100vh - 109px);
              min-height: calc(100vh - 109px);
            }
          }
          }
        }
      }
    }
    &.no-progressbar-txt {
      &:not(.wth-head-logo) {
        .section {
          .section-content {
            @media (min-width: 768px) {
              max-height: calc(100vh - 180px);
              min-height: calc(100vh - 180px);
            }
            @media (max-width: 767px) {
              max-height: calc(100vh - 120px);
              min-height: calc(100vh - 120px);
            }
          }
        }
      }
      &.wth-head-logo {
        &:not(.have-title-head) {
          &:not(.progressbar-bottom) {
            .section {
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 449px);
                  min-height: calc(100vh - 449px);
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 390px);
                  min-height: calc(100vh - 390px);
                }
              }
            }
          }
          &.progressbar-bottom {
            .section {
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 406px);
                  min-height: calc(100vh - 406px);
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 346px);
                  min-height: calc(100vh - 346px);
                }
              }
            }
          }
        }
        &.have-title-head {
          &.progressbar-bottom {
            &:not(.temp) {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 445px);
                    min-height: calc(100vh - 445px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 385px);
                    min-height: calc(100vh - 385px);
                  }
                }
              }
            }
          }
        }
      }
      &.wthout-header-img-container {
        &.no-progressbar-txt {
          &.progressbar-bottom {
            &.have-title-head {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 350px);
                    min-height: calc(100vh - 350px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 290px);
                    min-height: calc(100vh - 290px);
                  }
                }
              }
            }
          }
        }
      }
      &.wthout-header-img-container {
        &.no-progressbar-txt {
          &.slide-transition,
          &.smooth-transition {
            &.progressbar-bottom {
              &.have-title-head {
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 330px);
                      min-height: calc(100vh - 330px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 328px);
                      min-height: calc(100vh - 328px);
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.wthout-header-img-container {
        &.no-progressbar-txt {
          &.progressbar-bottom {
            &:not(.have-title-head) {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 225px);
                    min-height: calc(100vh - 225px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 169px);
                    min-height: calc(100vh - 169px);
                  }
                }
              }
              &.wthout-foot{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 175px);
                      min-height: calc(100vh - 175px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 105px);
                      min-height: calc(100vh - 105px);
                    }
                  }
                }
              }
              &.progressbar-extremelyTop{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 225px);
                      min-height: calc(100vh - 225px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 179px);
                      min-height: calc(100vh - 179px);
                    }
                  }
                }
                &.wthout-foot{
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 175px);
                        min-height: calc(100vh - 175px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 105px);
                        min-height: calc(100vh - 105px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.progressbar-extremelyTop {
      &.wthout-header-img-container {
        &.progressbar-extremelyTop {
          &.no-progressbar-txt {
            &.progressbar-bottom {
              &.have-title-head {
                .section {
                  .section-content {
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 390px);
                      min-height: calc(100vh - 390px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.progressbar-extremelyTop {
      &.wth-head-logo {
        &.progressbar-extremelyTop {
          &.no-progressbar-txt {
            &.progressbar-bottom {
              &.have-title-head {
                .section {
                  .section-content {
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 480px);
                      min-height: calc(100vh - 480px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.no-progressbar {
      &.wth-head-logo {
        .section {
          .section-content {
            @media (min-width: 768px) {
              max-height: calc(100vh - 352px);
              min-height: calc(100vh - 352px);
            }
            @media (max-width: 767px) {
              max-height: calc(100vh - 352px);
              min-height: calc(100vh - 352px);
            }
          }
        }
        &.wthout-foot{
          .section {
          .section-content {
            @media (min-width: 768px) {
              max-height: calc(100vh - 302px);
              min-height: calc(100vh - 302px);
            }
            @media (max-width: 767px) {
              max-height: calc(100vh - 302px);
              min-height: calc(100vh - 302px);
            }
          }
          }
        }
      }
    }
    // &.wth-progressbar-txt, {
    // }
    &:not(.no-progressbar) {
      &:not(.progressbar-bottom) {
        &.wth-head-logo {
          &:not(.have-title-head) {
            &:not(.progressbar-extremelyTop) {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 328px);
                    min-height: calc(100vh - 328px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 390px);
                    min-height: calc(100vh - 390px);
                  }
                }
              }
              &.no-progressbar-txt{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 300px);
                      min-height: calc(100vh - 300px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 272px);
                      min-height: calc(100vh - 272px);
                    }
                  }
                }
              }
              &.wthout-foot{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 278px);
                      min-height: calc(100vh - 278px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 390px);
                      min-height: calc(100vh - 390px);
                    }
                  }
                }
              }
              &.no-progressbar-txt{
                &.wthout-foot{
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 250px);
                        min-height: calc(100vh - 250px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 390px);
                        min-height: calc(100vh - 390px);
                      }
                    }
                  }
                }
              }
            }
          }
          &:not(.progressbar-extremelyTop) {
            &.have-title-head {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 409px);
                    min-height: calc(100vh - 409px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 470px);
                    min-height: calc(100vh - 470px);
                  }
                }
              }
              &.no-progressbar-txt{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 371px);
                      min-height: calc(100vh - 371px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 470px);
                      min-height: calc(100vh - 470px);
                    }
                  }
                }
              }
              &.wthout-foot{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 359px);
                      min-height: calc(100vh - 359px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 420px);
                      min-height: calc(100vh - 420px);
                    }
                  }
                }
                &.no-progressbar-txt{
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 321px);
                        min-height: calc(100vh - 321px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 420px);
                        min-height: calc(100vh - 420px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.wth-head-logo {
        &.progressbar-bottom {
          &:not(.have-title-head) {
            .section {
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 326px);
                  min-height: calc(100vh - 326px);
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 346px);
                  min-height: calc(100vh - 346px);
                }
              }
            }
            &.wthout-foot{
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 276px);
                    min-height: calc(100vh - 276px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 346px);
                    min-height: calc(100vh - 346px);
                  }
                }
              }
            }
          }
        }
      }
      &.wth-head-logo {
        &.progressbar-bottom {
          &.progressbar-extremelyTop{
            &:not(.have-title-head) {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 284px);
                    min-height: calc(100vh - 284px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 346px);
                    min-height: calc(100vh - 346px);
                  }
                }
              }
              &.wthout-foot{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 234px);
                      min-height: calc(100vh - 234px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 234px);
                      min-height: calc(100vh - 234px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.no-progressbar {
      &.wth-head-logo {
        &:not(.have-title-head) {
          .section {
            .section-content {
              @media (min-width: 768px) {
                max-height: calc(100vh - 277px);
                min-height: calc(100vh - 277px);
              }
              @media (max-width: 767px) {
                max-height: calc(100vh - 277px);
                min-height: calc(100vh - 277px);
              }
            }
          }
          &.wthout-foot{
            .section {
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 227px);
                  min-height: calc(100vh - 227px);
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 227px);
                  min-height: calc(100vh - 227px);
                }
              }
            }
          }
        }
      }
    }
    &.have-title-head {
      .fsop-container {
        height: calc(100vh - 230px) !important;
        @media (max-width: 767px) {
          height: calc(100vh - 180px) !important;
        }
      }
      &:not(.wthout-header-img-container) {
        &:not(.progressbar-bottom) {
          &:not(.wth-head-logo) {
            .section {
              .section-content {
                @media (min-width: 768px) {
                  max-height: calc(100vh - 460px) !important;
                  min-height: calc(100vh - 460px) !important;
                }
                @media (max-width: 767px) {
                  max-height: calc(100vh - 400px) !important;
                  min-height: calc(100vh - 400px) !important;
                }
              }
            }
          }
        }
      }
    }
    &.progressbar-extremelyTop {
      .fsop-container {
        @media (max-width: 767px) {
          height: calc(100vh - 240px) !important;
        }
      }
    }
    &.wth-head-logo {
      &.progressbar-bottom {
        &.wth-progressbar-txt {
          &.have-title-head {
            &:not(.no-progressbar) {
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 403px);
                    min-height: calc(100vh - 403px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 415px);
                    min-height: calc(100vh - 415px);
                  }
                }
              }
              &.wth-progressbar-txt{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 403px);
                      min-height: calc(100vh - 403px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 415px);
                      min-height: calc(100vh - 415px);
                    }
                  }
                }
              }
              &.no-progressbar-txt{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 389px);
                      min-height: calc(100vh - 389px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 389px);
                      min-height: calc(100vh - 389px);
                    }
                  }
                }
              }
              &.wthout-foot{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 353px);
                      min-height: calc(100vh - 353px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 565px);
                      min-height: calc(100vh - 565px);
                    }
                  }
                }
                &.no-progressbar-txt{
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 339px);
                        min-height: calc(100vh - 339px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 339px);
                        min-height: calc(100vh - 339px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.wth-head-logo {
      &.progressbar-bottom {
        &.have-title-head {
          &:not(.no-progressbar) {              
            &.no-progressbar-txt{
              .section {
                .section-content {
                  @media (min-width: 768px) {
                    max-height: calc(100vh - 389px);
                    min-height: calc(100vh - 389px);
                  }
                  @media (max-width: 767px) {
                    max-height: calc(100vh - 389px);
                    min-height: calc(100vh - 389px);
                  }
                }
              }
              &.wthout-foot{
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 359px);
                      min-height: calc(100vh - 359px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 359px);
                      min-height: calc(100vh - 359px);
                    }
                  }
                }
                &.progressbar-extremelyTop{
                  &.no-progressbar-txt{
                    .section {
                      .section-content {
                        @media (min-width: 768px) {
                          max-height: calc(100vh - 326px);
                          min-height: calc(100vh - 326px);
                        }
                        @media (max-width: 767px) {
                          max-height: calc(100vh - 326px);
                          min-height: calc(100vh - 326px);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.wth-head-logo {
      &.progressbar-bottom {
        &.wth-progressbar-txt,
        &.no-progressbar-txt {
          &.have-title-head {
            &:not(.no-progressbar) {
              &.smooth-transition,
              &.slide-transition {
                .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 394px);
                      min-height: calc(100vh - 394px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 394px);
                      min-height: calc(100vh - 394px);
                    }
                  }
                }
                &.wthout-foot{
                  .section {
                  .section-content {
                    @media (min-width: 768px) {
                      max-height: calc(100vh - 344px);
                      min-height: calc(100vh - 344px);
                    }
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 344px);
                      min-height: calc(100vh - 344px);
                    }
                  }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.hv-iframe {
      @media (min-width: 768px) {
        height: 100vh;
      }
      .section {
        .section-content {
          @media (min-width: 768px) {
            max-height: calc(100vh - 327px);
            min-height: calc(100vh - 327px);
          }
          @media (max-width: 767px) {
            max-height: calc(100vh - 270px);
            min-height: calc(100vh - 270px);
          }
        }
      }
      &.wth-head-logo {
        .section {
          .section-content {
            @media (min-width: 768px) {
              max-height: calc(100vh - 418px);
              min-height: calc(100vh - 418px);
            }
            @media (max-width: 767px) {
              max-height: calc(100vh - 358px);
              min-height: calc(100vh - 358px);
            }
          }
        }
      }
      &.custom-theme{
        &.no-bg-image{
          &.wth-head-logo{
            &.no-progressbar-txt{
              &.slide-transition,&.have-title-head{
                .section {
                  .section-content {
                    @media (max-width: 767px) {
                      max-height: calc(100vh - 191px);
                      min-height: calc(100vh - 191px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.progressbar-top {
          .section {
            .section-content {
              @media (min-width: 768px) {
                max-height: calc(100vh - 375px);
                min-height: calc(100vh - 375px);
              }
              @media (max-width: 767px) {
                max-height: calc(100vh - 315px);
                min-height: calc(100vh - 315px);
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &:not(.progressbar-top) {
          &:not(.progressbar-bottom) {
            &:not(.no-progressbar) {
              &:not(.have-title-head) {
                &:not(.progressbar-extremelyTop) {
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 251px);
                        min-height: calc(100vh - 251px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 290px);
                        min-height: calc(100vh - 290px);
                      }
                    }
                  }
                  &.wthout-foot{
                    .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 200px);
                        min-height: calc(100vh - 200px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 240px);
                        min-height: calc(100vh - 240px);
                      }
                    }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wthout-header-img-container {
      &.wth-progressbar-txt {
        &.progressbar-bottom {
          &:not(.no-progressbar) {
            &:not(.smooth-transition) {
              &:not(.slide-transition) {
                &:not(.progressbar-extremelyTop){
                  .section {
                    .section-content {
                      @media (min-width: 768px) {
                        max-height: calc(100vh - 264px);
                        min-height: calc(100vh - 264px);
                      }
                      @media (max-width: 767px) {
                        max-height: calc(100vh - 264px);
                        min-height: calc(100vh - 264px);
                      }
                    }
                  }
                  &.wthout-foot{
                    .section {
                      .section-content {
                        @media (min-width: 768px) {
                          max-height: calc(100vh - 134px);
                          min-height: calc(100vh - 134px);
                        }
                        @media (max-width: 767px) {
                          max-height: calc(100vh - 134px);
                          min-height: calc(100vh - 134px);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.full-width {
    padding-top: 0;
    .fsop-container {
      @media (min-width: 1300px) {
        max-width: 1280px;
        margin: auto;
      }
      box-shadow: none !important;
    }
    &.custom-theme {
      height: auto !important;
      min-height: 100vh;
      &.hv-iframe{
        height: 100vh !important;
      min-height: 100vh !important;
      }
    }
    .survey-btn-sec {
      .btn-actions {
        box-shadow: none !important;
      }
    }
    .question-container {
      .list-item {
        margin-left: 0px !important;
        margin-bottom: 0 !important;
        @media(max-width: 500px){
          margin-left: 0px !important;
        }
      }
    }
  }
  .btn-md {
    padding: 8px 15px !important;
    text-align: center;
    justify-content: center;
  }
}

.wizard-gutter {
  // @media (min-width: 768px) {
  //   max-height: calc(100vh - 82px);
  //   min-height: calc(100vh - 82px);
  // }

  // @media (max-width: 767.98px) {
  //   max-height: calc(100vh - 150px);
  //   min-height: calc(100vh - 150px);
  //   padding: 15px;
  // }

  overflow: auto;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    height: calc(100vh - 199px);
  }
}

.wth-head-logo {
  .wizard-gutter {
    @media (min-width: 768px) {
      height: calc(100vh - 320px) !important;
    }
    .thanks-msg {
      padding-top: 33px;
    }
  }
}
.sub-nxt-btns,
.back-btns {
  .btn {
    display: flex;
    align-items: center;
  }
}

.back-btns {
  i {
    margin-right: 16px;
  }
}

.sub-nxt-btns {
  display: flex;
  align-items: center;

  .btn {
    margin-left: 10px;
  }

  i.svg {
    margin-left: 16px;
  }
}

.svg {
  &.svg-angle-left {
    width: 9px;
    height: 14px;
    background-position: -94px -22px;
  }

  &.svg-angle-right {
    width: 9px;
    height: 14px;
    background-position: -94px -4px;
  }

  &.hint-icon {
    width: 14px;
    min-width: 14px;
    height: 14px;
    background-position: -108px -4px;
    min-height: 14px;
    &:not(.no) {
      margin-left: 10px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.form-group {
  position: relative;
}
.d-flex {
  display: flex;
}
.align-item-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: space-between;
}

.switch-box {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 24px;
}

.switch-box input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
textarea {
  &.wth-border {
    border-radius: 3px !important;
  }
}
.form-control {
  &.wth-border {
    border: 1px solid #ced4da !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    white-space: normal;
    &.txt-type {
      height: 38px;
    }
    line-height: normal;
    &:disabled {
      cursor: not-allowed;
    }
  }
}
.me-10 {
  margin-right: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.me-30 {
  margin-right: 30px !important;
}
.me-3{
  margin-right: 1rem;
}
.me-15 {
  margin-right: 15px !important;
}
.ms-15 {
  margin-left: 15px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.form-head {
  font-weight: 600;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 8px;
  margin-top: 30px;
  font-size: 14px;
}

.header-left-item {
  @media (max-width: 767px) {
    width: 100%;
  }
}

fsop-captcha,
fsop-text-entry {
  .question-container {
    @media (max-width: 500px) {
      overflow-y: hidden;
    }
  }
}

@media (max-width: 767.98px) {
  .pac-container + div {
    // z-index: 50000 !important;
    left: 0 !important;
    top: 0px !important;
  }
}
@media (max-width: 767.98px) {
  .tooltip {
    width: 100%;
    top: 0px !important;
    left: 0 !important;
    height: 100% !important;
    display: flex !important;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    .arrow {
      display: none;
    }
    .tooltip-inner {
      overflow: auto;
      max-height: 100%;
    }
  }
  .tooltip-modal {
    .modal-content {
      background-color: #000 !important;
      color: #fff !important;
      padding: 12px 15px;
      border-radius: 4px !important;
      word-break: break-word;
      .close-modal {
        position: absolute;
        top: -3px;
        right: -3px;
        color: #000;
        background: #fff;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
    }
  }
}
.emailpopup {
  text-align: center;
  background: #e5ebf6;
  border: 1px solid #b1c7f8;
  padding: 25px 15px;
  .form-control {
    max-width: 300px;
    margin: auto;
    background: #fff !important;
    &:not(:hover) {
      &:not(:focus) {
        border: 1px solid #d8d6d6 !important;
      }
    }
    &:hover,&:focus{
      border: 1px solid #b6b3b3 !important;
    }
  }
  .text-danger {
    margin-top: 10px;
  }
}
.theme-preview {
  input {
    &.input-form-control {
      box-shadow: none !important;
      outline: 0 !important;
      border-radius: 0;
      height: 37px;
      padding: 6px 12px;
      font-size: 13px;
      line-height: 1;
      border: 1px solid #e6e4e4;
      background: #eef2f5;
      color: #000000;
      width: 100%;
    }
  }
}
.embed-icons {
  margin-top: 13px;
  li {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.sb-default .sb-wrapper {
  height: 40px;
}
ngb-popover-window {
  .popover-body {
    min-width: 190px;
  }
}
app-compaign-dashboard {
  .ngx-datatable {
    overflow: visible !important;
  }
}
app-survey-header-uploader {
  .settings-modal {
    width: 100% !important;
  }
}

app-question-section {
  &.scroll-hide {
    .section-content {
      /* width */
      &::-webkit-scrollbar {
        width: 0px !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #e9e9e9;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  &.scroll-visible {
    .section-content {
      /* width */
      &::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #e9e9e9;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .section-content {
    /* width */
    &::-webkit-scrollbar {
      width: 8px !important;
      height: 8px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
.simple-tree {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    float: left;
    &.filter-tree-node {
      margin: 0;
      position: relative;
      margin-left: 8px;
      list-style: none;
      .icon {
        display: inline-block;
        &:not(.no-mar) {
          margin-left: 5px;
          margin-right: 8px;
        }
        margin-top: 9px;
        color: #777;
        cursor: pointer;
        width: 18px;
        text-align: center;
        &.fa-plus-circle {
          margin-left: 0px;
        }
        &:hover {
          color: #217bbb;
        }
      }
    }
  }
}
.single-filter {
  float: left;
  .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
    margin: 0;
    overflow: visible;
    .input-group-btn {
      display: inline-block;
      float: left;
      width: auto;
    }
    .form-control {
      float: left;
      display: inline-block;
      width: auto;
      height: 30px !important;
      border: 1px solid #217bbb;
      &:hover,
      &:focus {
        border: 1px solid #003f6b;
      }
    }
  }
}
.ngx-datatable.bootstrap.contactform-table
  .datatable-body-row.datatable-row-even {
  background: #eff5f7   !important;
}
.ngx-datatable.bootstrap.contactform-table
  .datatable-body-row.datatable-row-odd {
  background: #fdfefe  !important;
}
.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
  z-index: 1;
}
.resizer,
.resizing {
  border-right: 1px dashed #bebebe;
  height: 200vh !important;
}
.toolbar-left {
  display: flex;
  margin-top: 6px;
  align-items: flex-start;
}
.filter-group {
  .left {
    display: flex;
    float: left;
  }
  .input-group-btn {
    &:not(:last-child) {
      margin-right: -1px;
    }
  }
  .ng-select-container {
    border: 1px solid #217bbb;
    border-radius: 0;
    min-height: 30px !important;
    height: 30px !important;
  }
  .ng-select-multiple {
    .ng-select-container {
      max-width: 200px;
      height: auto !important;
      .ng-value-container {
        .ng-placeholder {
          top: 8px;
        }
      }
    }
  }
}
.btn {
  &.dark {
    &.outline {
      color: #515559;
      border: 1px solid #515559;
      background: #fff;
      &:hover,
      &:active,
      &:focus {
        background: #515559;
        color: #fff;
      }
    }
  }
}
.filters-client {
  .filter-response-count {
    display: inline-block;
    padding-top: 7px;
  }
}
.filters-client-filter-name {
  margin-top: 8px;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

.dropdown-menu {
  &.custom-dropdown {
    padding-top: 0px !important;
    max-height: 200px;
    overflow: auto;
  }
  .search-box {
    position: sticky;
    z-index: 1;
    top: 0;
  }
}
.dropdown-submenu {
  list-style-type: none;
  padding-left: 0px;
  .dropdown-item {
    padding-left: 32px;
  }
}

.hv-submenu {
  position: relative;
  padding-left: 28px !important;
  i {
    position: absolute;
    left: 16px;
    top: 9px;
    display: inline-block;
    width: 12px;
    height: 12px;
    &:before {
      content: "";
      width: 0px;
      border-left: 6px solid #313131;
      border-top: 5px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 5px solid transparent;
      position: absolute;
    }
  }
  &.open {
    i {
      &:before {
        left: -2px;
        top: 3px;
        transform: rotate(90deg);
        transform-origin: center;
      }
    }
  }
}

footer {
  .nmbr {
    padding-top: 20px;
  }
  .progress {
    margin-bottom: 20px !important;
  }
}
.side-togglable {
  width: 208px;
  height: 100vh;
  position: fixed;
  .side-bar-toggle {
    position: absolute;
    top: 70px;
    z-index: 999;
  }
  .sidemenu {
    padding-top: 48px;
  }
}
app-update-user{
  .page-breadcrumb{
    margin: 5px 0 28px;
  }
}
.collapsedw {
  .fsop-container {
    width: calc(100% - 0px) !important;
    margin-left: 0 !important;
    > .warning-message{
      margin-left: 40px
    }
    h1,.page-breadcrumb {
      padding-left: 50px;
    }
    > li {
      padding-left: 40px;
    }
    > label {
      padding-left: 40px;
    }
    > .bkg-inner{
      padding: 20px 40px;
    }
  }
  app-update-user{
    .page-breadcrumb{
      padding-left: 55px !important;
    }
  }
}
.casa-table {
  .datatable-header {
    overflow: visible !important;
    position: sticky;
    top: 0;
    z-index: 99999;
    .sort-btn {
      right: 10px !important;
    }
  }
  datatable-footer {
    display: none !important;
  }
  .datatable-header-cell {
    background: #e3eaf4;
    white-space: nowrap !important;
  }
  .datatable-body-row {
    height: auto !important;
  }
  .datatable-body-cell {
    height: 100% !important;
    white-space: normal !important;
    display: flex !important;
    align-items: center;
  }
}
.toolbar-body {
  .collapse {
    background: #fbfbfb;
    padding: 15px 25px 5px 20px;
    margin-bottom: 20px;
    border: 1px solid #ececec;
  }
}
.alertTimePicker {
  ngb-popover-window {
    &:before {
      display: none;
    }
    height: auto !important;
    max-width: 100% !important;
    .arrow {
      display: none;
    }
    .popover-body {
      min-width: 246px !important;
    }
    .btn {
      min-width: auto !important;
    }
    .ngb-tp-meridian {
      .btn-outline-primary {
        min-width: 52px !important;
      }
    }
  }
}

.default-minmalist {
  .progress {
    height: 8px;
    // border-radius: 0px !important;
    .counter {
      width: 16px !important;
      height: 16px !important;
    }
  }
}
app-spelling-check-modal {
  .modal-body {
    height: calc(100vh - 260px);
    overflow: auto;
  }
}
.overflow-x-auto {
  overflow-x: auto;
}
.header-img-container {
  position: relative;
}
.visibility-hide {
  visibility: hidden;
}
a.dropdown-item {
  cursor: pointer;
  &:active {
    color: #fff !important;
  }
}
.checkbox-wthlabel {
  label {
    margin-bottom: 0;
    margin-left: 12px;
  }
}
.show {
  > .btn-primary.dropdown-toggle {
    color: #fff !important;
    border-color: #217bbb;
    background-color: #217bbb;
  }
}
.dropdown-item {
  padding: 10px 13px;
  line-height: normal;
}
.custom-theme {
  .survey-msg-side-preview {
    position: fixed;
  }
}
.settings-modal-preview {
  .survey-msg-side-preview {
    top: 52px;
    right: 20;
    text-align: center;
    max-width: calc(100vw - 708px);
    width: 100%;
  }
  .custom-theme {
    .highestScrollBar {
      padding-left: 20px;
      padding-right: 20px;
      .question-section-head {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
.form-check {
  min-height: 18px;
}
app-edit-response {
  .question-container {
    .quest {
      > h3 {
        min-width: auto !important;
      }
    }
  }
}

.ng-dropdown-panel {
  z-index: 9999 !important;
  .ng-dropdown-panel-items {
    .ng-option {
      padding: 10px !important;
    }
  }
}
.ng-select .ng-select-container {
  background-color: #eef2f5 !important;
  border-radius: 0px !important;
  border: 1px solid transparent !important;
  &:hover {
    box-shadow: none !important;
  }
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none !important;
}
.pac-container {
  // background-color: #FFF;
  z-index: 9999;
}
.casa-table {
  .datatable-body-row {
    &.datatable-row-even {
      .datatable-body-cell {
        background: #eef3f7 !important;
        width: 100% !important;
      }
    }
    &.datatable-row-odd {
      .datatable-body-cell {
        background: #f5f8fd !important;
        width: 100% !important;
      }
    }
  }
}
@media (max-width: 767px) {
  .wth-out-qnum {
    .wth-qnum {
      display: none !important;
    }
  }
}
.default-theme {
  .section-header {
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .header-img {
    max-height: 50px !important;
  }
}

.info-box {
  padding: 16px 12px 15px 64px;
  color: #32363a;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  position: relative;
  line-height: 20px;
  .nav-item {
    &.dropdown {
      display: none;
    }
  }
  &:hover {
    .nav-item {
      &.dropdown {
        display: block;
      }
    }
  }
  .box-icon {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 34px;
    color: #fff;
  }
  .t-switch {
    position: absolute;
    padding: 6px 3px;
    left: 44px;
    top: 10px;
    cursor: pointer;
  }
  &.blue {
    background: #fbfcfe;
    .box-icon {
      background: #AEC6CF;
    }
  }
  &.purple {
    background: #fbfcfe;
    .box-icon {
      background: #C3B1E1;
    }
  }

  &.pink {
    background: #fbfcfe;
    .box-icon {
      background: #F8C8DC;
    }
  }
  .toggle-data {
    padding: 10px 8px 0 0;
    font-size: 12px;
    line-height: 1.4;
  }
}
.fsop-alert-modal {
  h4 {
    font-size: 16px;
    margin-bottom: 0;
    p {
      margin: 0px;
    }
  }
}
.flex-item.form-group {
  &:not(.box-item) {
    margin-bottom: 0px !important;
  }
}
app-common-logic-row {
  .item {
    .group-input {
      .form-control {
        border-radius: 6px;
        &.ng-invalid.ng-touched{
          border: 1px solid red !important;
        }
      }
      .ng-select .ng-select-container {
        border-radius: 6px !important;
      }
    }
  }
  .flex-item{
    margin-bottom: .5rem;
  }
}
.text-danger2 {
  color: rgb(217, 83, 79);
}
.text-success2 {
  color: rgb(103, 186, 47);
}
.displaylogic-icon {
  width: 26px;
  height: 10px;
  background-position: -7px -602px;
}
.branchlogic-icon {
  width: 25px;
  height: 26px;
  background-position: -8px -640px;
}
.skipLogic-icon {
  width: 22px;
  height: 16px;
  background-position: -9px -617px;
}
.text-danger-pastel {
  color: #ff6961;
}
ngb-modal-window{
  &.logic-modal{
    &:not(.design-setting){
      .modal-lg{
        @media(min-width: 1500px){
          max-width: 1020px !important;
        }
      }
    }
  }
}
app-all-email-list{
  .table-app-edit,.table-app-deactivate{
    position: relative;
    top: 1px;
  }
}
.ques-container.focus_layout {
  .previewMode {
    opacity: 0.3;
  }
}
.bulk-lnk{
  font-size: 14px;
}
fsop-drill-down{
  .question-container{
    .list-item{
      .item{
        .form-inline{
          align-items: flex-start;
          .form-control-label{
            max-width: 140px;
            line-height: normal;
            display: flex !important;
            align-items: center;
            justify-content: flex-start;
            min-height: auto;
            margin: auto 0 auto !important;
          }
        }
      }
    }
  }
}
fsop-dynamic-sum{
  .question-container{
    .form-inline{
      .list-item{
        .item{
          align-items: flex-start !important;
          .form-control-label{
            max-width: 140px;
            line-height: normal;
            display: flex !important;
            align-items: center;
            min-height: auto;
          }
          .form-control{
            margin: 0 !important;
          }
        }
      }
    }
  }
}
select{
  &.form-control{
    text-overflow: ellipsis;
  }
}
.ng-select-focused{
  &:not(.ng-select-opened){
    .ng-input{
      input{
        cursor: pointer!important;
        display: none;
      }
    }
  }
}
.tox .tox-dialog--width-lg{
  height: calc(100vh - 90px)!important;
}
.recommend-block2{
  ul{
    li{
      line-height: normal;
      margin-bottom: 3px;
      font-size: 11px;
    }
  }
}
.multichoice-box{
  &:not(.form-label-above){
    .radio-item{
      .form-check-label{
        .opt-txt{
          padding-left: 7px;
        }
      }
    }
  }
  @media(max-width: 551px){
    &.form-label-above{
      .radio-item{
        .form-check-label{
          .opt-txt{
            padding-left: 7px;
            display: inline-block;
          }
        }
      }
    }
  }
}
app-sa-logging{
  ngx-datatable{
    .visible{
      width: calc(100% + 8px)!important;
    }
  }
}
app-preview{
  .custom-theme{
    &:not(.full-width){
      overflow: hidden;
    }
  }
}
.twofa-modal{
  > .modal-dialog{
    max-width: 380px;
    .form-group{
      max-width: 100%!important;
    }
  }
}
// app-question-section{
//   @media(max-width: 600px){
//     width: 100%!important;
//   }
// }

app-survey-preview{
  .form-group.list-item{
    @media(min-width: 768px){
      margin-left: 46px !important;
      margin-bottom: 0 !important;
    }
    @media(max-width: 767px){
      margin-left: 0px !important;
      margin-bottom: 0 !important;
    }
  }
}

app-survey-preview{
  .list-item{
    margin-left: 45px !important;
  }
}
app-survey-preview{
  .section-content ul li .fsop-checkbox,app-edit-response ul li .fsop-checkbox{
    width: 0;
    -webkit-appearance: none;
  }
  .section-content ul li .fsop-checkbox:after, app-edit-response ul li .fsop-checkbox:after{
    background: transparent;
  }
}
survey-editor{
  .survey-builder{
    .quest{
      h6{
        margin-bottom: 20px;
      }
    }
    fsop-multichoice{
      select.form-control{
        margin-top: 13px;
      }
      .quest{
        h6{
          margin-bottom: 8px;
        }
      }
    }
    fsop-rank-order{
      .table{
        margin-top: 0!important;
      }
    }
    fsop-dynamic-sum{
      .form-inline {
        .list-item {
          .item{
            margin-top: 0!important;
          }
        }
      }
    }
  }
}
app-survey-preview{
  fsop-rank-order{
    .question-container{
      .form-group{
        margin-bottom: 0px!important;
      }
    }
  }
  .list-item{
    .form-group{
      .table-responsive{
        .table{
          margin-top: 0px!important;
        }
      }
    }
  }
}
fsop-rank-order{
  .form-inline.rank-order-list{
    .form-group{
      @media(max-width: 767px){
        flex-wrap: nowrap;
        .form-control{
          max-width: 50px;
        }
      }
    }
  }
}
fsop-drill-down {
  .question-container {
    .list-item {
      .item {
        .form-inline{
          @media(max-width: 1099px){
            flex-wrap: nowrap;
            width: 100%;
          }
          @media(max-width: 767px){
            .form-control-label{
              width: calc(100% - 150px);
              max-width: initial;
            }
            .form-control{
              max-width: 150px;
            }
          }
        }
      }
    }
  }
}
fsop-dynamic-sum{
  .form-inline{
    .list-item{
      @media(max-width: 767px){
        width: 100%!important;
      }
      .item {
        @media(max-width: 767px){
          padding-right: 0px;
          .form-control-label{
            width: calc(100% - 150px);
            max-width: initial!important;
          }
          .form-control{
            max-width: 150px;
          }
        }
      }
    }
  }
}
fsop-descriptive-text{
  .free-text{
    margin-bottom: 0px!important;
  }
}

// .custom-theme .focus_layout ul .content-background .question-container{
//   margin-top: 16vh;
// }

// .custom-theme .focus_layout ul .content-background:is(:last-child) .question-container{
//   margin-bottom: 16vh;
// }
fsop-rank-order{
  .list-input-styled{
    li{
      &:last-child{
        margin-bottom: 0!important;
      }
    }
  }
}
.custom-drop{
  background: #eef2f5 !important;
  display: flex!important;
  align-items: center;
  .ng-select-container{
    background-color: transparent !important;
    border-radius: 0px !important;
    border: 0!important;
    height: auto!important;
    min-height: auto!important;
    .ng-placeholder{
      font-size: 13px;
      color: #000000 !important;
    }
  }
  .ng-dropdown-panel-items{
    width: 100%;
  }
  .ng-value-container{
    min-height: 19px;
    display: flex!important;
    align-items: center;
  }
  .ng-input{
    input{
      display: none;
    }
  }
}
.descType{
  .free-text{
    &:last-child{
      padding-bottom: 0!important;
    }
  }
}

.form-group {
  margin-bottom: 1rem;
}

// .form-inline .form-control {
//   display: inline-block;
//   width: auto;
//   vertical-align: middle;
// }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  // margin-bottom: 0.5rem;
}

.cdk-drag-dragging ::marker{
  content: '';
}

.cdk-drag-preview::marker{
  content: '';
}


// border-bottom: 1px solid #cdd1d5!important;
// margin-top: 0;
// padding: 22px 20px;
// margin-bottom: 0;
// border: 1px solid transparent!important;

// margin-left: 76px;
// margin-bottom: 1rem;

// margin-left: 30px;

// display: flex;
// overflow: hidden;

.rank_order_preview{
  align-items: center;
  display: flex;
  .rank_order_position{
    margin-left: 10px;
  }
}

.cdk-drag-preview{
  input[type='checkbox']{
    top: 35px;
  }
  .no-pointer-events{
    height: 100%;
    .question-container{
      height: 100%;
      border: 1px solid #cdd1d5!important;
      background: #fff;
      opacity: 0.8;
      padding-top: 22px;
      .quest{
        display: flex;
        overflow: hidden;
        margin-left: 37px;
        h3{
        color: #bfbfbf;
        font-size: 14px;
        font-weight: 700;
        min-width: 20px;
        padding-right: 25px;
        display: inline-block;
        padding-top: 2px;
        position: absolute;
        }
      }
      .list-item{
        margin-left: 76px;
        .fsop-radio{
          top: 5px;
        }
      }
    }
  }

}

.pl-4, .px-4{
  padding-left: 1.5rem!important;
}

.ms-1, .mx-1{
  margin-left: 0.25rem!important;
}

.toast-warning{
  color: #155724 !important;
}

.me-2, .mx-2{
  margin-right: 0.5rem!important;
}
app-collapsable-card,app-schedule-email .col-sm-10{
  .card{
    border-radius: 4px!important;
    .card-header{
      font-size: 13px;
      min-height: 39px;
      align-items: center;
      color: #3F3F3F;
      background: #F7F7F7;
    }
  }
}
.portal-tab-cont{
  padding: 16px 0px 16px 22px;
}
.tox-tinymce{
  border-radius: 0px!important;
}
.contact-table{
  .datatable-header{
    background: #E3EAF4;
    .datatable-header-cell{
      color: #6B6B6B;
    }
  }
  .ngx-datatable.bootstrap .datatable-footer{
    margin-bottom: 0px;
  }
  .datatable-footer-inner{
    background: #EEF3F6;
    color: #505050;
    font-weight: 400;
  }
  .ngx-datatable.bootstrap .datatable-header .datatable-header-cell{
    padding: 5px 12px!important;
    .checkbox-wthlabel label{
      margin: 0 0 0 14px !important;
    }
  }
  .ngx-datatable.bootstrap .datatable-body-row .datatable-body-cell{
    display: flex;
    align-items: center;
    .form-control{
      background: #D8E2EB !important;
    }
    .datatable-body-cell-label{
      padding-bottom: 0px !important;
      width: 100%;
    }
  }
  .ngx-datatable.bootstrap:not(.no) .datatable-body-row:not(.active).datatable-row-odd{
    background: #ffffff !important;
  }
  .ng-select .ng-select-container{
    background-color: #d8e2eb !important;
    .ng-value-container .ng-placeholder{
      color: #000;
    }
  }
  .ng-select .ng-arrow-wrapper .ng-arrow{
    border-color: #000 transparent transparent !important;
  }
}
.rater-modal{
  .contact-table{
    .ngx-datatable.bootstrap:not(.no) .datatable-body-row:not(.active).datatable-row-odd{
      background: #F5F8FD !important;
    }
    .ngx-datatable.bootstrap:not(.no) .datatable-body-row:not(.active).datatable-row-even{
      background: #F3F6F9  !important;
    }
    datatable-body-cell{
      display: flex !important;
      align-items: center !important;
    }
    .ngx-datatable.bootstrap .datatable-body-row .datatable-body-cell{
      .form-control{
        background: #EEF2F5 !important;
      }
      .datatable-body-cell-label{
        width: 100%;
      }
    }
    .datatable-footer-inner{
      background: #ECECEC;
    }
    .actions{
      padding: 6px 0px 5px;
    }
    .ng-select .ng-select-container{
      background-color: #eef2f5 !important;
      .ng-value-container .ng-placeholder{
        color: #000;
      }
    }
    .ng-select .ng-arrow-wrapper .ng-arrow{
      border-color: #000 transparent transparent !important;
    }
  }
}
.text-right{
  text-align: right;
}
app-scheduled-rater-group{
  ng-select{
    .ng-select-container .ng-value-container .ng-placeholder{
      top: 10px!important;
    }
  }
}
app-project-report{
  .rptTabCon{
    padding: 0 7px 0px 12.5px;
    .datatable-header{
      background: #F6F6F6;
    }
    .datatable-scroll{
      .datatable-row-wrapper{
        datatable-body-cell{
          display: flex;
          align-items: center;
          .datatable-body-cell-label{
            padding-top: 5px;
          }
        }
      }
    }
  }
}
.fltr-row{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  app-progress-bar{
    min-width: 210px;
  }
  .periods {
    min-width: 127px;
    .dropdown{
      width: 100%;
      height: 25px;
      border-left: 1px solid #ccc;
      padding-left: 25px;
      margin-left: 30px;
      margin-right: 30px;
      .dropdown-toggle{
        width: 100%;
        height: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 127px;
        &:after{
          content: "\e609";
          display: inline-block;
          font-family: "fsopIcons";
          font-style: normal;
          font-weight: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          border: 0!important;
        }
      }
    }
  }
}
app-portal-campaign-report{
  .datatable-footer{
    display: none!important;
  }
  .ngx-datatable{
    height: auto!important;
  }
}
.portal-table,.request-assessment-table,.rater-table,.category-table{
  .datatable-header .datatable-header-inner .datatable-header-cell{
    color: #6B6B6B!important;
    border-bottom: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    background: #F6F6F6;
  }
  .datatable-body-row .datatable-body-cell {
    cursor: pointer;
    .datatable-body-cell-label{
      padding-top: 5px;
    }
  }
}
.rater-table{
  datatable-body-cell{
    background: #ffffff;
    border-bottom: 1px solid #E8E8E8;
    display: flex !important;
    align-items: center !important;
  }
  .datatable-header{
    background: #ffffff;
  }
  .datatable-row-center{
    datatable-body-cell{
      &:last-child{
        .datatable-body-cell-label{
          margin-left: auto!important;
        }
      }
    }
  }
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{
  padding-top: 0;
}
// .ngx-datatable.bootstrap.compaign-dashboard-table .datatable-body-row.active{
//   background: #d0f2f9!important;
// }
// .ngx-datatable.compaign-dashboard-table.bootstrap:not(.no) .datatable-body-row:not(.active).datatable-row-even{
//   background: #f3fefe !important;
// }
// .ngx-datatable.compaign-dashboard-table.bootstrap:not(.no) .datatable-body-row:not(.active).datatable-row-odd{
//   background: #ffffff !important;
// }
// .ngx-datatable.compaign-dashboard-table{
//   .datatable-header{
//     background: #d0e9f2!important;
//     .datatable-header-cell{
//       color: #6b6b6b;
//       .datatable-icon-sort-unset::before{
//         opacity: 1!important;
//       }
//     }
//   }
// }
.app-portal-data-management{
  .datatable-footer-inner{
    display: block!important;
  }
}


.bulk-lnk{
  text-decoration: none !important;
}

a{
  text-decoration: none;
}


select.form-control{
  line-height: normal !important;
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  appearance: auto !important;

}


.label-margin{
  margin-bottom: 0.5rem;
}

.cdk-drag{
  &.single-option{
    &.cdk-drag-dragging,&.cdk-drag-draging,&.cdk-drag-draging,&.cdk-drag-preview{
      list-style: none;
      opacity: 0.9;
      .input-group{
        input{
          width: 100%;
          padding: 4px 10px !important;
          font-size: 12px;
          line-height: 1;
          border-radius: 0;
          height: 28px !important;
        }
      }
    }
  }
}

.validation-box{
  label{
    margin-bottom: 5px;
  }
}

.rater-grp-select{
  .ng-dropdown-header{
    padding: 0;
    label{
      padding: 13px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: normal;
      font-size: 14px;
      min-height: 1.3em;
      input{
        min-height: 16px;
      }
      span{
        margin-left: 0.6em;
      }
    }
  }
  .ng-option{
    label{
      cursor: pointer;
    }
    input{
      &:after{
        content: "";
      }
    }
    &-marked{
      input{
        &:after {
          background: white;
          content: "";
          color: #777;
          font-family: "fsopIcons";
        }
      }
    }
  }
}

.ng-select {
  &.ng-invalid{
    .ng-select-container{
      border-color: red!important;
    }
  }
}

.tooltip {
  &.show {
    &.bs-tooltip-top{
      margin-bottom: 6px !important;
      .tooltip-arrow::before{
        top: -2px!important;
      }
    }
  }
}

.dash-stats-container{
  min-height: 126px;
}



.ct-act-btn{
  .tooltip{
    margin-right: 8px!important;
  }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before{
    left: -3px;
  }
}


.impersonated{
  border: 3px solid #23c4b6;
  .side-bar-toggle{
    display: none;
  }
  .inner-shell{
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 101px);
    margin-top: 57px;
  }
  .app-header{
    border: 3px solid #23c4b6;
    border-bottom: 0px;
    left: 0px;
    right: 0;
    .logo-ctn{
      top: 2px;
      left: 2px;
      height: 57px;
    }
  }
  .rater-modal{
    max-height: calc(100vh - 23px);
  }
}

.portal-view{
  &.inner-shell{
    margin-top: 57px;
    .side-bar-toggle{
      display: none;
    }
  }
}

.personated-strip{
  padding: 6px 10px;
  font-size: 12px;
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #23c4b6;
  color: #fff;
}



.overlay {
	
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(#3379B5, 0.2);
	text-align: center;
	z-index: 10;
  .circle{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: url('../src/assets/images/loader-icon.png') no-repeat center;
    position: absolute;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
}


/* Loader 2 */
.loader-2 {
	display: block;
	  height: 52px;
	  width: 52px;
	  -webkit-animation: loader-2-1 3s linear infinite;
			  animation: loader-2-1 3s linear infinite;
  }
  @-webkit-keyframes loader-2-1 {
	  0%   { -webkit-transform: rotate(0deg); }
	  100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-1 {
	  0%   { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
  }
  .loader-2 span {
	  display: block;
	  position: absolute;
	  top: 0; left: 0;
	  bottom: 0; right: 0;
	  margin: auto;
	  height: 52px;
	  width: 52px;
	  clip: rect(16px, 52px, 52px, 0);
	  -webkit-animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
			  animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes loader-2-2 {
	  0%   { -webkit-transform: rotate(0deg); }
	  100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-2 {
	  0%   { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
  }
  .loader-2 span::before {
	  content: "";
	  display: block;
	  position: absolute;
	  top: 0; left: 0;
	  bottom: 0; right: 0;
	  margin: auto;
	  height: 52px;
	  width: 52px;
	  border: 3px solid blue;
	  border-radius: 50%;
	  -webkit-animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
			  animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes loader-2-3 {
	  0%   { -webkit-transform: rotate(0deg); }
	  100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-3 {
	  0%   { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
  }

  .overlay{
	  z-index: 9999999 !important;
  }
  .loader-2 span::after {
	  content: "";
	  display: block;
	  position: absolute;
	  top: 0; left: 0;
	  bottom: 0; right: 0;
	  margin: auto;
	  height: 52px;
	  width: 52px;
	  border: 3px solid #3379B5;
	  border-radius: 50%;
  }

  .tox-menu{
    max-width: 450px!important;
  }

  app-survey-builder-sidemenu{
    .side-bar-toggle{
      position: fixed;
    }
  }